import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  CircularProgress,
  Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/common/Loader';
import { getAllBoardDetails, editCourseDetail, getpublisher, AddCourse, updateCourse } from '../Servies/AdmiServices';

const FileSelectionDialog = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle color={'Red'}>Validation Error</DialogTitle>
      <DialogContent>Please select All the value before choosing a file.</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default function CreateCourse() {
  const [courseName, setCourseName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [board, setBoard] = useState([]);
  const [publish, setPublish] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPublisher, setSelectedPublisher] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const fileInputRef = useRef(null);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [courseData, setCourseData] = useState({});
  const params = new URLSearchParams(window.location.search);
  const courseId = params.get('courseId');

  useEffect(() => {
    handleChangeBoard();
    handleChangepublish();
  }, []);

  useEffect(() => {
    if (courseId) {
      editCourseDetail(courseId)
        .then((response) => {
          setCourseData(response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [courseId]);

  const handleChangeBoard = async () => {
    try {
      const boardDetails = await getAllBoardDetails();
      const boardCode = boardDetails?.data?.map((data) => ({
        boardName: data?.boardName,
        boardId: data?.id,
      }));
      setBoard(boardCode);
    } catch (error) {
      console.error('Error fetching board details:', error);
    }
  };

  const handleChangepublish = async () => {
    try {
      const Details = await getpublisher();
      const Code =
        Details?.data?.length > 0 &&
        Details?.data?.map((data) => ({
          Name: data?.publisherName,
          Id: data?.id,
        }));
      setPublish(Code);
    } catch (error) {
      console.error('Error fetching publisher details:', error);
    }
  };

  const handleChangeCoursename = (e) => {
    setCourseName(e.target.value);
  };

  const handleChangeDisplayname = (e) => {
    setDisplayName(e.target.value);
  };

  const onSelectBoard = (event) => {
    const selectedBoardObject = board.find((board) => board.boardName === event.target.value);
    setSelectedBoard(selectedBoardObject);
  };

  const onSelectpublisher = (event) => {
    const selectedPublisherObject = publish.find((publisher) => publisher.Name === event.target.value);
    setSelectedPublisher(selectedPublisherObject);
  };

  const handleFileButtonClick = () => {
    if (
      (!courseData.course && !courseName) ||
      (!courseData?.displayName && !displayName) ||
      (!courseData.boardName && !selectedBoard) ||
      (!courseData.Publisher && !selectedPublisher)
    ) {
      setIsDialogOpen(true);
    } else {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file ? file.name : '');
  };

  useEffect(() => {}, [selectedFile]);

  const handleAddCourse = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const BoradId = selectedBoard ? selectedBoard.boardId : '';
    const coursename = courseName;
    const coursedisplayname = displayName;
    const publisherid = selectedPublisher ? selectedPublisher.Id : '';
    const file = selectedFile;

    if (courseId) {
      // Update course if courseId is present
      updateCourse(courseId, file)
        .then((res) => {
          if (res?.data.success === true) {
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate(-1);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Update Course Error:', error);
        });
    } else {
      AddCourse(BoradId, coursename, coursedisplayname, publisherid, file)
        .then((res) => {
          if (res?.data.success === true) {
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
            navigate('/dashboard/Courses');
          } else {
            setIsLoading(false);
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('AddCourse error:', error);
        });
    }
  };

  const handlepage = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title>Create Course</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Course
          </Typography>
        </Stack>
        <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
          <CardContent>
            <form style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <TextField
                name="course"
                label="Course Name"
                variant="outlined"
                value={courseData?.course || courseName || ''}
                onChange={handleChangeCoursename}
              />

              <TextField
                name="displayName"
                label="Course Display Name"
                variant="outlined"
                value={courseData?.displayName || displayName || ''}
                onChange={handleChangeDisplayname}
              />

              <FormControl variant="outlined">
                <InputLabel id="demo-multiple-name-label">Boards</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={courseData.boardName ? courseData.boardName : selectedBoard ? selectedBoard.boardName : ''}
                  onChange={onSelectBoard}
                  label="Board"
                >
                  {board.map((boards) => (
                    <MenuItem key={boards.boardId} value={boards.boardName}>
                      {boards.boardName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined">
                <InputLabel id="demo-publisher-label">Publisher</InputLabel>
                <Select
                  labelId="demo-publisher-label"
                  id="demo-publisher"
                  value={courseData.Publisher ? courseData.Publisher : selectedPublisher ? selectedPublisher.Name : ''}
                  onChange={onSelectpublisher}
                  label="Publisher"
                >
                  {publish.map((publisher) => (
                    <MenuItem key={publisher.Id} value={publisher.Name}>
                      {publisher.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Display the selected file name */}
              {selectedFileName && (
                <Typography variant="body2" color="textSecondary">
                  Selected File: {selectedFileName}
                </Typography>
              )}

              <Button
                variant="contained"
                component="span"
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                style={{
                  width: '260px',
                  height: '40px',
                  alignSelf: 'center',
                  backgroundColor: 'blue',
                  color: 'white',
                  marginRight: '679px',
                }}
                onClick={handleFileButtonClick}
              >
                Upload course Structure
              </Button>
              <input
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                style={{ display: 'none' }}
                id="courseFileInput"
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: '150px', alignSelf: 'center', marginLeft: '1rem' }}
                  onClick={handleAddCourse}
                >
                  Add Course
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  sx={{
                    width: '120px',
                    textAlign: 'center',
                    marginLeft: '2rem',
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                  onClick={handlepage}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
        <FileSelectionDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      </Container>
    </>
  );
}
