import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import '../Styles/product.css';
import { toast } from 'react-toastify';
import { CreatenewUac, createTeachersEdge, getAllCourseDetails, getAllSubjectDetails } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';
import { GENERAL_SAVE_MESSAGE } from '../utils/message';

export default function CreateTeacharsUAC() {
  const [isLoading, setIsLoading] = useState(false);
  const [uacCount, setUacCount] = useState('');
  const [clientId, setClientId] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
  const navigate = useNavigate();
  const [Courses, setCourese] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [formFields, setFormFields] = useState([
    {
      courseId: '',
      subjectId: '',
      uacCount: '',
      clientId: '',
    },
  ]);

  const handleChange = (index, event, property) => {
    const values = [...formFields];
    values[index][property] = event.target.value;
    setFormFields(values);
  };



  const handleAddField = () => {
    setFormFields([
      ...formFields,
      {
        courseId: '',
        subjectId: '',
        uacCount: '',
        clientId: '', // Set default value for clientId for new fields
      },
    ]);
  };

  const onSelectCourse = (index, event) => {
    const selectedCourseObject = Courses.find((course) => course.courseName === event.target.value);
    setSelectedCourse(selectedCourseObject);


    if (selectedCourseObject) {
      fetchSubjectData(selectedCourseObject.courseId);
    }
    handleChange(index, event, 'courseId');
  };

  const onSelectSubject = (index, event) => {
    const selectedSubjectObject = subject.find((subject) => subject.subjectName === event.target.value);
    handleChange(index, event, 'subjectId');
    setSelectedSubject(selectedSubjectObject);

  };
  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();
    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourese(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);
  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);

      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const handleRemoveField = (index) => {
    const values = [...formFields];
    values.splice(index, 1);
    setFormFields(values);
  };

  const handleUacCodeChange = (index, e) => {
    setUacCount(e.target.value);
    handleChange(index, e, 'uacCount');
  };
  const handleClientId = (index, e) => {
    setClientId(e.target.value);
    handleChange(index, e, 'clientId');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = formFields.map((field) => {
      return {
        courseId: field.courseId,
        subjectId: field.subjectId,
        uacCount: field.uacCount,
        clientId: field.clientId,
      };
    });

    createTeachersEdge(payload)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        toast.success(GENERAL_SAVE_MESSAGE, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate('/dashboard/TeachersEdgeUAC');
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      });
  };

  const handlepage = () => {
    navigate(-1);
  };

  return (
    <>
      {showLoader && <Loader />}

      <Helmet>
        <title> Dashboard: Create TeachersEdgeUAC</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5, marginRight: '10px' }}>
          Create Teachers Edge UAC
        </Typography>

        {/* Checkboxes */}

        {/* Input fields and search button */}
        <form onSubmit={handleSubmit}>
          {formFields.map((field, index) => (
            <div key={index} style={{ display: 'flex', marginBottom: '20px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size="small">
                <InputLabel id="courses-label">Courses</InputLabel>
                <Select
                  labelId="courses-label"
                  // value={selectedCourse ? selectedCourse.courseName : ''}
                  // onChange={onSelectCourse}

                  value={field.courseId}
                  onChange={(e) => onSelectCourse(index, e)}
                  input={<OutlinedInput label="Courses" />}
                  startAdornment={
                    <InputAdornment position="start">{isLoading && <CircularProgress size={15} />}</InputAdornment>
                  }
                >
                  {Courses.map((course) => (
                    <MenuItem key={course.courseId} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: 270 }} size="small">
                <InputLabel id="level-label">Level</InputLabel>
                <Select
                  labelId="level-label"
                  // value={selectedSubject ? selectedSubject.subjectName : ''}
                  // onChange={onSelectSubject}

                  value={field.subjectId}
                  onChange={(e) => onSelectSubject(index, e)}
                  input={<OutlinedInput label="Level" />}
                  startAdornment={
                    <InputAdornment position="start">{isLoading && <CircularProgress size={15} />}</InputAdornment>
                  }
                >
                  {subject.map((subj) => (
                    <MenuItem key={subj.levelId} value={subj.subjectName}>
                      {subj.subjectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                name="UAC Count"
                label="UAC Count"
                style={{ width: '200px', marginBottom: '20px', marginLeft: '20px' }}
                // value={uacCount}
                value={field.uacCount}
                onChange={(e) => handleUacCodeChange(index, e)}
              />

              <TextField
                name="clientId"
                label="clientId"
                style={{ width: '200px', marginBottom: '20px', marginLeft: '20px' }}
                // value={uacCount}
                value={field.clientId}
                onChange={(e) => handleClientId(index, e)}
              />

              {formFields.length > 1 && (
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ m: 1, width: 70, textAlign: 'center' }}
                  onClick={() => handleRemoveField(index)}
                >
                  Remove
                </Button>
              )}
            </div>
          ))}
          <Button type="button" variant="outlined" sx={{ ml: 1 }} onClick={handleAddField}>
            Add
          </Button>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <Button type="submit" variant="contained" sx={{ width: '120px', textAlign: 'center' }}>
              Create
            </Button>

            <Button
              type="button"
              variant="contained"
              sx={{
                width: '120px',
                textAlign: 'center',
                marginLeft: '1rem',
                backgroundColor: 'white',
                color: 'black',
              }}
              onClick={handlepage}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Container>
    </>
  );
}
