import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import '../Styles/product.css';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAllCourseDetails, getAllSubjectDetails, getnotification } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';

export default function Notification() {
  const navigate = useNavigate();
  const [Courses, setCourese] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [NotificationData, setNotificationData] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  // const [searchValue, setSearchValue] = useState('');

  const [showAllData, setShowAllData] = useState(true);
  const headers = ['SrNO', 'Massage', 'Status', 'Action'];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onSelectCourse = (event) => {
    const selectedCourseObject = Courses.find((course) => course.courseName === event.target.value);
    setSelectedCourse(selectedCourseObject);

    if (selectedCourseObject) {
      fetchSubjectData(selectedCourseObject.courseId);
    }
  };

  const onSelectSubject = (event) => {
    const selectedSubjectObject = subject.find((subject) => subject.subjectName === event.target.value);
    setSelectedSubject(selectedSubjectObject);
  };
  const onSelect = (event) => {
    setSelectedTopic(event?.target?.value);
  };
  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();
    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourese(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  const handleEditUser = (id) => {
    navigate(`/dashboard/ManageNotification?id=${id}`);
  };

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);

      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const handleAddNotificationClick = () => {
    navigate('/dashboard/ManageNotification');
  };
  const data = {
    courseId: selectedCourse ? selectedCourse.courseName : '',
    subjectId: selectedSubject ? selectedSubject.levelId : '',
    isTopic: selectedTopic,
  };
  const handleShowData = () => {
    getnotification(data)
      .then((response) => {
        if (response.data !== null) {
          setNotificationData(response.data);
          setIsCardVisible(true);
        } else {
          setIsCardVisible(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  };
  const serialNumber = 1;
  const notifaciondata = NotificationData.filter((item) => item !== null).map((item) => {
    return {
      SrNO: serialNumber + 1,
      Massage: item?.notificationBody,
      Status: item.isActive ? (
        <Button variant="contained" color="primary">
          Active
        </Button>
      ) : (
        ''
      ),
      Action: (
        <div>
          <FaEdit
            style={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={() => handleEditUser(item.id)} // Pass the user ID to the edit handler
          />
        </div>
      ),
    };
  });
  const [sortedData, setSortedData] = useState(NotificationData);

  const handleSearchInputChange = (searchVal) => {
    const searchedValue = searchVal.target.value.toLowerCase();

    const filteredRows = NotificationData.filter((row) => {
      // Use a return statement here to return the result of .map()
      return row !== null && row.notificationBody.toLowerCase().includes(searchedValue);
    });

    setSortedData(filteredRows);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Notifications </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
          <Button variant="contained" onClick={handleAddNotificationClick}>
            Add Notification
          </Button>
        </Stack>

        <form>
          <div className="main" style={{ display: 'flex' }}>
            <div className="col-md-3">
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Courses</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedCourse ? selectedCourse.courseName : ''}
                  onChange={onSelectCourse}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {Courses.map((course) => (
                    <MenuItem key={course.courseId} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
            <div className="col-md-3" style={{ marginLeft: '10px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Level</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedSubject ? selectedSubject.subjectName : ''}
                  onChange={onSelectSubject}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {subject.map((subject) => (
                    <MenuItem key={subject.levelId} value={subject.subjectName}>
                      {subject.subjectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3" style={{ marginLeft: '10px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="topic">Topic</InputLabel>
                <Select
                  labelId="topic"
                  id="topic"
                  value={selectedTopic}
                  onChange={onSelect}
                  input={<OutlinedInput label="notification topic" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem value={'true'}>TOPIC</MenuItem>
                  <MenuItem value={'false'}>NON TOPIC</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={handleShowData}
              sx={{ width: '120px', height: '40px', marginTop: '10px', textAlign: 'center' }}
            >
              Search
            </Button>
          </div>
        </form>
        <br />
        {isCardVisible && (
          <Card>
            <div style={{ alignItems: 'center' }}>
              <TextField
                label="Search"
                variant="outlined"
                // value={searchValue}
                onChange={(searchVal) => handleSearchInputChange(searchVal)}
                // onKeyPress={handleEnterKeyPress} // Call handleSearch when Enter is pressed
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaSearch />
                    </InputAdornment>
                  ),
                }}
                // style={{ marginRight: '10px' }}
              />

              <TableData
                data={sortedData.length === 0 ? notifaciondata : sortedData}
                headers={headers}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </div>
          </Card>
        )}
      </Container>
    </>
  );
}
