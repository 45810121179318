import { Helmet } from 'react-helmet-async';

import { useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, TextField, DialogTitle, DialogContent, DialogActions, Table, Dialog, TableContainer, TableBody, TableRow, TableCell, Input } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaInfoCircle } from 'react-icons/fa';
import Iconify from '../components/iconify';
import { SerachUser, deleteUsers, getQuizeSummaryReport, updateUserProfileName } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function UserPage() {
  const navigate = useNavigate();
  const [phoneNumber, setphoneNumber] = useState('');
  const [Name, setName] = useState('');
  const [userdata, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [removeUser, setRemoveUser] = useState();
  const headers = ['Name', 'UserName', 'Profile User Name', 'Email ID', 'Status', 'Action', 'Quizz Prize'];
  const [showLoader, setShowLoader] = useState(false);
  const [prizeOpen, setPrizeOpen] = useState(false);
  const [profileNameOpen, setProfileNameOpen] = useState(false)
  const [profileName, setProfileName] = useState('');
  const [quizData, setQuizData] = useState(null);

  const [IsUserDataVisible, setIsUserDataVisible] = useState(false);

  // const handleMobileNumberChange = (e) => {
  //   setphoneNumber(e.target.value);
  // };

  const handleMobileNumberChange = (e) => {
    // Use regex to remove non-numeric characters from the input
    const numericValue = e.target.value.replace(/\D/g, '');
    // Limit the input to a maximum of 10 characters
    const truncatedValue = numericValue.slice(0, 10);
    setphoneNumber(truncatedValue);
  };

  const handlenameChange = (e) => {
    setName(e.target.value);
  };

  const handleNewUserClick = () => {
    navigate('/dashboard/manageuser');
  };
  const handleEditUser = (userId) => {
    navigate(`/dashboard/manageuser?userId=${userId}`);
  };

  const [isCardVisible, setIsCardVisible] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    setIsLoading(true); // Set loading state to true

    if (!Name && !phoneNumber) {
      // Both name and mobile number are empty; you can handle this case as needed
      console.log('Please enter a name or a mobile number.');
      setIsLoading(false);
      return;
    }

    SerachUser(Name, phoneNumber)
      .then((res) => {
        if (res?.data?.SUCCESS === true) {
          setUserData(res?.data?.Data);
          setIsCardVisible(true);
        } else {
          // console.log('No matching users found.');
          setIsCardVisible(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log('Error:', error);
        setIsLoading(false);
        setIsCardVisible(false);
      });
  };
  const handleDeleteUser = (userId) => {
    setIsLoading(true);
    deleteUsers(userId)
      .then((response) => {
        if (response?.data.SUCCESS === true) {
          // setRemoveUser(response?.data);

          toast.success(response?.data?.Data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response?.data?.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      });
  };

  const handleViewQuizeSummary = (userId) => {
    setIsLoading(true);
    getQuizeSummaryReport(userId)
      .then((response) => {
        if (response?.status === 200) {
          setQuizData(response.data)
          setPrizeOpen(true)
          console.log(quizData)
        } else {
          toast.error("Error Occured...", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  };

  const handleUserProfileNameChange = (e) => {
    e.preventDefault()
    setIsLoading(true)
    updateUserProfileName(profileName.userId, profileName.profileUserName).then((response) => {
      if (response.status === 200) {
        toast.success("Profile User Name Changed", { position: toast.POSITION.TOP_RIGHT, });
        setIsLoading(false)
        setProfileNameOpen(false)
        setIsCardVisible(false)
        setUserData([])
      } else {
        toast.error("Error Occured", { position: toast.POSITION.TOP_RIGHT, });
        setIsLoading(false)
      }
    });
  }


  const handleClose = () => {
    setPrizeOpen(false);
  };

  const users = userdata
    .filter((item) => item !== null)
    .map((item) => {
      return {
        Name: item?.name,
        UserName: item?.userName,
        "Profile User Name": (<>
          <span>{item?.profileUserName}  </span>
          <FaEdit
            style={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={() => { setProfileName(item); setProfileNameOpen(true) }}
          />
        </>
        ),
        'Email ID': item.email,
        Status: item.isActive ? (
          <Button variant="contained" color="primary">
            Active
          </Button>
        ) : (
          'Inactive'
        ),
        Action: (
          <div>
            <FaEdit
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => handleEditUser(item.userId)} // Pass the user ID to the edit handler
            />
            <FaTrash
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => handleDeleteUser(item.userId)} // Pass the user ID to the delete handler
            />

          </div>
        ),
        'Quizz Prize': (
          <Button variant="contained" color="primary"
            onClick={() => { handleViewQuizeSummary(item.userId) }}
          >View Prize</Button>
        )
      };
    });

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> User </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            // onClick={
            //   Navigate('/dashboard/app/manageuser')
            //   }>
            onClick={handleNewUserClick}
          >
            New User
          </Button>
        </Stack>

        <form onSubmit={handleSubmit}>
          <TextField
            name="Name"
            label="Name"
            style={{ width: '260px', marginRight: '10px' }}
            onChange={handlenameChange}
          />
          <TextField
            name="phoneNumber"
            label="Mobile No"
            style={{ width: '260px', marginRight: '10px' }}
            onChange={handleMobileNumberChange}
            value={phoneNumber} // Set the value of the TextField to the phoneNumber state
          />

          <button type="submit" style={{ width: '120px', height: '40px' }}>
            Search
          </button>
        </form>
        <br />

        {isCardVisible && (
          <Card>
            {users.length > 0 ? (
              <TableData data={users} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
            ) : (
              'No Data Found'
            )}
          </Card>
        )}
        <Dialog maxWidth={'md'} fullWidth open={prizeOpen} onClose={handleClose}>
          <DialogTitle>User Quizz Information</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><Typography variant="body1">Username</Typography></TableCell>
                    <TableCell><Typography variant="body1">{quizData?.data?.userName}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography variant="body1">Number of Quizzes</Typography></TableCell>
                    <TableCell><Typography variant="body1">{quizData?.data?.numberOfQuizzes}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography variant="body1">Total Points</Typography></TableCell>
                    <TableCell><Typography variant="body1">{quizData?.data?.totalPoints}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography variant="body1">Total Score</Typography></TableCell>
                    <TableCell><Typography variant="body1">{quizData?.data?.totalScore}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography variant="body1">Total Payout</Typography></TableCell>
                    <TableCell><Typography variant="body1">{quizData?.data?.totalPayout}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography variant="body1">Best Rank</Typography></TableCell>
                    <TableCell><Typography variant="body1">{quizData?.data?.bestRank}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography variant="body1">Active Eduteck User</Typography></TableCell>
                    <TableCell><Typography variant="body1">{quizData?.data?.isAnActiveEduteckUser.toString()}</Typography></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth={'md'} open={profileNameOpen} onClose={() => { setProfileNameOpen(false) }}>
          <DialogTitle>Update Profile User Name</DialogTitle>
          <DialogContent >
            <form style={{ padding: '10px' }} onSubmit={handleUserProfileNameChange}>
              <TextField name="ProfileUserName" label="Profile User Name"
                value={profileName.profileUserName} onChange={(e) => { setProfileName({ ...profileName, profileUserName: e.target.value }) }}
                style={{ width: '260px', marginRight: '10px' }}
              />
              <button type="submit" style={{ width: '120px', height: '40px' }}>
                Update
              </button>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setProfileNameOpen(false) }} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
