import { TOKEN } from '../config/localstorage_keys';
import api from './api';
import { LOGIN_ENDPOINT, USERLOGIN } from './URLS';

import { clearLocalStorage, setLocalStorage } from '../utils/strorage';

const login = (username, password) => {
  return api
    .post(LOGIN_ENDPOINT, {
      username,
      password,
    })
    .then((response) => {
      setLocalStorage(TOKEN, response.data.response.accessToken);
      localStorage.setItem("mobile", response.data.response?.mobile);
      localStorage.setItem("name", response.data.response?.name);
      localStorage.setItem("role", response.data.response?.roles);
      // localStorage.setItem("role", "marketing"); 
      return response;
    })
    .catch((err) => {
      return err;
    });
};

const logout = () => {
  clearLocalStorage();
};

export { login, logout };
