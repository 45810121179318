import { Helmet } from 'react-helmet-async';

import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  alertTitleClasses,
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
// import JSZip from 'jszip';
// import XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { FaEdit, FaClipboardList, FaTrash } from 'react-icons/fa';
import { IconName } from 'react-icons/ai';

import { getAssignmentResult } from '../Servies/AdmiServices';

import Iconify from '../components/iconify';

import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function AssignmentResult() {
  const [student, setStudents] = useState([]);
  const headers = ['Student Name', 'Total Questions', 'Score'];
  const [showTable, setShowTable] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  console.log(id, 'consoleid');

  const fetchData = () => {
    setIsLoading(true);
    // const parsedSchoolId = schoolId ? parseInt(schoolId, 10) : null; // Parse schoolId to integer

    getAssignmentResult(id)
      .then((response) => {
        setShowTable(response.data.data);
        console.log('check', response.data.data);
        setIsLoading(false);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  console.log(showTable, 'helooo');
  useEffect(() => {
    fetchData();
  }, []);

  const specificColumns = Array.isArray(showTable)
    ? showTable.map((item) => {
        console.log('div', item, item?.grade);
        return {
          'Student Name': item.userName,
          'Total Questions': item?.totalQuestions,
          Score: item.correctCount,
        };
      })
    : [];

  return (
    <>
      {isLoading && <Loader />}

      {/* {showLoader && <Loader />} */}
      <ToastContainer />
      <Helmet>
        <title> Client </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Allern Admin Platform - Assignment Result
          </Typography>
        </Stack>

        <br />
        <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <Button variant="contained" color="primary" onClick={handlePrevPage}>
            Previous
          </Button>
          <Button variant="contained" color="primary" onClick={handleNextPage}>
            Next
          </Button>
        </div> */}
        <br />
      </Container>
    </>
  );
}
