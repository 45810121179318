import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Stack,
  Button,
  Tooltip,
  LinearProgress,
  Box,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import Chart from 'react-apexcharts';
// import CustomBarChart from '../components/CustomBarChart';
import { LineChart } from '@mui/x-charts';

import { AppCurrentVisits } from '../sections/@dashboard/app';

import Loader from '../components/common/Loader';

import { getFinalReportCard } from '../Servies/AdmiServices';

import Iconify from '../components/iconify';
// import { useReportContext } from '../hooks/ReportContext';

// import Iconify from '../components/iconify';
// import {
//   AppTasks,
//   AppNewsUpdate,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppTrafficBySite,
//   AppWidgetSummary,
//   AppCurrentSubject,
//   AppConversionRates,
// } from '../sections/@dashboard/app';
const chartSetting = {
  xAxis: [
    {
      label: 'Activity Percentage',
    },
  ],
  width: 500,
  height: 400,
};
const dataset = [
  {
    percentage: 50,
    month: 'Apr',
  },
  {
    percentage: 50,
    month: 'may',
  },
  {
    percentage: 50,
    month: 'jun',
  },
  {
    percentage: 50,
    month: 'feb',
  },
  {
    percentage: 50,
    month: 'juz',
  },
  {
    percentage: 50,
    month: 'feb',
  },
  {
    percentage: 50,
    month: 'feb',
  },
  {
    percentage: 50,
    month: 'feb',
  },
  {
    percentage: 50,
    month: 'feb',
  },
];

const valueFormatter = (value) => `${value}mm`;

const updatedYAxis = [
  {
    scaleType: 'band', // Change to linear scale
    dataKey: 'month', // Assuming 'seoul' is the dataKey for the y-axis
    domain: [0, 100], // Set the desired domain (0 to 100 in this case)
  },
];

const dummyApiResponse = [
  {
    id: 1,
    name: 'Faye D’Souza',
    division: 'A',
    grade: 6.9,
    points: 236,
    rank: 'A++',
    subjects: [
      { name: 'General Knowledge1', value: 30, subjectid: 1 },
      { name: 'Grammar', value: 25, subjectid: 2 },
      { name: 'Computer Science', value: 35, subjectid: 3 },
      { name: 'Drawing', value: 28, subjectid: 4 },
      { name: 'Hindi', value: 30, subjectid: 5 },
    ],
    interests:
      ' Faye shows an acute interest in seeking knowledge and self-learning. Encouraged to help her with topic variety. Recommendations: Topics why binary, evolution of the computer',
    imageUrl: '/User-Avatar-PNG-Transparent-Image.png',
  },
];

const StudentReport = () => {
  const pdfRef = useRef();
  const [progressReports, setProgressReports] = useState();
  const [subjectsvalues, setSubjectsvalues] = useState([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [subjectname, setSubjectName] = useState();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [studentFinalReports, setStudentFinalReports] = useState([]);
  const [quizReport, setQuizReport] = useState([]);
  const [assessmentReport, setAssessmentReport] = useState([]);
  const [contentUpload, setContentUpload] = useState([]);
  const [markObtain, setMarkObtain] = useState([]);
  const [quizData, setQuizData] = useState([]);
  // const [chartData, setChartData= useState();

  const userId = new URLSearchParams(location.search).get('userId');
  const courseId = new URLSearchParams(location.search).get('selectedCourseId');
  const subjectId = new URLSearchParams(location.search).get('selectedSubjectId');
  const chapterId = new URLSearchParams(location.search).get('selectedChapterId');
  const name = new URLSearchParams(location.search).get('name');
  console.log(courseId);
  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };
  //   useEffect(() => {
  //     if (userId) {
  //       setIsLoading(true);

  //       getStudentReport(userId, courseId)
  //         .then((response) => {
  //           setProgressReports(response.data);
  //           setSubjectsvalues(response?.data?.subjects);
  //           setIsLoading(false);

  //           setIsLoading(false);

  //           // setChecked(response.data?.Data?.isActive);
  //         })
  //         .catch((error) => {
  //           setIsLoading(false);

  //           console.error('Error fetching user data:', error);
  //         });
  //     }
  //   }, [userId, courseId]);
  //   console.log('hello kunal', subjectsvalues);

  const fetchFinalReports = () => {
    setIsLoading(true);
    // const parsedSchoolId = schoolId ? parseInt(schoolId, 10) : null; // Parse schoolId to integer
    const requestData = [
      {
        index: 0,
        courseId,
        subjectId,
        chapterId: [chapterId],
        userId,
      },
    ];
    getFinalReportCard(requestData)
      .then((response) => {
        setStudentFinalReports(response.data.data);
        setQuizReport(response.data.data.quiz_report);
        setAssessmentReport(response.data.data.assessment_report); // Set assessment report data
        setContentUpload(response.data.data.content_report);
        setMarkObtain(response.data.data.marks_report);
        // Extract quizData from marks_report
        const extractedQuizData = response.data.data.marks_report.map((subject) => ({
          subjectName: subject.subjectName,
          quizData: subject.quizData.map((quiz) => ({
            quizName: quiz.quizName,
            quizMarksPercent: quiz.quizMarksPercent,
          })),
        }));
        setQuizData(extractedQuizData);
        console.log('checked', extractedQuizData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  console.log(studentFinalReports);
  useEffect(() => {
    fetchFinalReports();
  }, []);
  // const staticValue = 10; // Change this value as needed

  const StaticProgressBar = ({ value }) => {
    return <LinearProgress variant="determinate" value={value} />;
  };

  const chartData =
    // progressReport?.subjects.length > 0 &&
    subjectsvalues.map((subject) => ({
      label: subject.name,
      value: subject.value,
      time: subject.subjectActivationDate,
      subjectName: subject.name, // Include the subject name

      subjectId: subject.subjectId,
    }));

  const subjectData =
    // progressReport?.subjects.length > 0 &&
    subjectsvalues.map((subject) => ({
      label: subject.name,
      value: subject.value,
      time: subject.subjectActivationDate,
      subjectId: subject.subjectId,
      subjectName: subject.name, // Include the subject name
    }));

  // useEffect(() => {
  //   if (progressReport?.length > 0) {
  //     setChartData(
  //       progressReport?.subjects.map((subject) => ({
  //         label: subject.name,
  //         value: subject.value,
  //       }))
  //     );
  //   }
  // }, [progressReport]);
  console.log('mini', subjectsvalues, chartData);
  // const { progressReport } = useProgressReport();
  const navigate = useNavigate();
  const options = {
    chart: {
      type: 'donut',
      height: 350,
      events: {
        dataPointSelection(event, chartContext, config) {
          if (options.labels && options.labels.length > 0) {
            const dataPointIndex = config.dataPointIndex;
            const clickedSubject = options.labels[dataPointIndex];
            const subid = chartData?.length > 0 && chartData?.map((item) => item.subjectId);
            const subjectid = subid[dataPointIndex];
            const subjectName = chartData?.length > 0 && chartData?.map((item) => item.subjectName)[dataPointIndex];
            console.log(
              'Data Point Index:',
              dataPointIndex,
              'Clicked Subject:',
              clickedSubject,
              subid,
              subjectid,
              subjectName
            );
            navigate(
              `/dashboard/personal?userId=${userId}&subjectId=${subjectid}&courseId=${courseId}&subjectName=${subjectName}`
            );
          }
        },
      },
    },
    labels: chartData?.length > 0 && chartData?.map((item) => item.label),

    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
        donut: {
          size: '75%',
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const handleRoutePage = () => {
    const hardcodedUserId = '0437ada8-6150-4657-8971-73edb3eaeef6';
    const hardcodedCourseId = 'CR0047';
    navigate(`/dashboard/RepoAndSubject?userId=${hardcodedUserId}&courseId=${hardcodedCourseId}`);
  };

  // const handleChartClick = (event, chartContext, config) => {
  //   // Handle your click event logic here
  //   console.log('Chart clicked!', event, chartContext, config);
  // };

  // const downloadPDF = () => {
  //   const input = pdfRef.current;
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new JsPDF('p', 'mm', 'a4');
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
  //     const imgY = 30;
  //     pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
  //     pdf.save('report.pdf');
  //   });
  // };

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save('report.pdf'); // Save the PDF with the specified file name
    });
  };

  console.log(subjectData);

  return (
    <>
      {isLoading && <Loader />}

      <Container maxWidth="xl" ref={pdfRef}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Report Card of - {name}
            <img
              src="/favicon/logo-02 (1).png"
              height={50}
              style={{ marginLeft: '850px', marginTop: '-45px' }}
              alt="logo"
            />{' '}
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={10} sm={4} md={4}>
            <Card sx={{ height: '100%', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <img src="/favicon/image_processing20200424-23023-niqwy1.png" height={200} alt="logoss" />

                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" onClick={handleRoutePage}>
                    Name: {studentFinalReports?.name}
                  </Typography>
                  {/* <Typography variant="h6" color="text.secondary">
                    Board: {progressReports?.division}Stateboard
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Grade: {progressReports?.grade}8th
                  </Typography> */}
                  <Typography variant="h6" color="text.secondary">
                    Grading Period: {studentFinalReports?.course_name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ maxWidth: 250, height: '100%' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" onClick={handleNewUserClick}>
                    {progressReports?.name}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Division: {progressReports?.division}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Grade: {progressReports?.grade}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Points: {progressReports?.points}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Rank: {progressReports?.rank}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid> */}

          <Grid item xs={10} sm={4} md={4}>
            <Card sx={{ height: '100%', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Synopsis
                  </Typography>
                  <br />
                  <Typography variant="h6" color="text.secondary">
                    Total Scholarship earned till date {studentFinalReports?.total_scholarship_earn}
                  </Typography>
                  <br />
                  <Typography gutterBottom variant="h5" component="div">
                    Overall Performance Analysis:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {studentFinalReports?.performance}Student demonstrates strong performance in Mathematics, while
                    showing improvement needed in Science.
                  </Typography>
                  <br />
                  <Typography gutterBottom variant="h5" component="div">
                    Recommendations
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {studentFinalReports?.recommendation}Student should focus on watching the videos and attempting
                    assessments. Encourage the student to maintain consistent attendance and punctuality.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* 
          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ height: '100%' }}>
              <ReactApexChart
                options={options}
                series={chartData?.map((item) => item.value)}
                type="donut"
                height={350}
              />
            </Card>
          </Grid> */}

          <Grid item xs={10} sm={4} md={4}>
            <Card sx={{ height: '100%', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Video Completion:
                  </Typography>
                  {contentUpload.map((content, index) => (
                    <React.Fragment key={index}>
                      <Typography variant="h6" gutterBottom component="div">
                        {content.subjectName}
                      </Typography>
                      <div style={{ position: 'relative' }}>
                        <LinearProgress
                          variant="determinate"
                          value={(content.contentPercent / content.contentPercent) * 100}
                          title={`${(content.contentPercent / content.contentPercent) * 100}%`}
                          sx={{ width: '100%', borderRadius: 5 }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            transform: 'translate(50%, -50%)',
                            textAlign: 'center',
                            width: 'auto',
                            padding: '2px',
                            borderRadius: '4px',
                            background: '#fff',
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {`${(content.contentPercent / content.contentPercent) * 100}%`}
                          </Typography>
                        </Box>
                      </div>
                    </React.Fragment>
                  ))}

                  <br />
                  <Typography gutterBottom variant="h5" component="div">
                    Assessment Completion:
                  </Typography>
                  {assessmentReport.map((assessment, index) => (
                    <React.Fragment key={index}>
                      <Typography variant="h6" gutterBottom component="div">
                        {assessment.subjectName}
                      </Typography>
                      <div style={{ position: 'relative' }}>
                        <LinearProgress
                          variant="determinate"
                          value={(assessment.attemptedAsessment / assessment.totalAssessment) * 100}
                          title={`${(assessment.attemptedAsessment / assessment.totalAssessment) * 100}%`}
                          sx={{ width: '100%', borderRadius: 5 }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            transform: 'translate(50%, -50%)',
                            textAlign: 'center',
                            width: 'auto',
                            padding: '2px',
                            borderRadius: '4px',
                            background: '#fff',
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {`${(assessment.attemptedAsessment / assessment.totalAssessment) * 100}%`}
                          </Typography>
                        </Box>
                      </div>
                    </React.Fragment>
                  ))}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={10} sm={4} md={4}>
            <Card sx={{ height: '100%', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
              <CardActionArea sx={{ height: '100%' }}>
                {quizReport.map((quiz, index) => (
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Marks Analysis
                    </Typography>
                    <br />

                    <Typography gutterBottom variant="h5" component="div">
                      Subject: {quiz.subjectName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Total Quiz {quiz?.totalQuiz}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Attempted Quiz {quiz?.attemptedQuiz}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Marks {quiz?.quizMarksPercent}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Attendence {quiz?.attendencePercent}
                    </Typography>
                  </CardContent>
                ))}
              </CardActionArea>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <AppCurrentVisits
              options={generatePieChartOptions(chartData)}
              title="Subjects Growth"
              chartData={subjectsvalues.map((subject) => ({
                label: subject.name,
                value: subject.value,
              }))}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid> */}

          <Grid item xs={12} sm={6} md={6}>
            <Card sx={{ height: '100%', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
              <Typography gutterBottom variant="h5" component="div">
                Subject Progress
              </Typography>
              {quizData.map((subject, index) => (
                <React.Fragment key={index}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>{subject.subjectName}</div>
                  <LineChart
                    xAxis={[{ data: subject.quizData.map((quiz) => quiz.quizMarksPercent) }]}
                    series={[{ data: subject.quizData.map((quiz) => quiz.quizName) }]}
                    width={500}
                    height={300}
                  />
                </React.Fragment>
              ))}
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={10} md={6}>
            <Card style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
              <Typography gutterBottom variant="h5" component="div">
                Progress Report ( Month wise progress)
              </Typography>
              {monthlyReports && monthlyReports.length > 0 && (
                <CustomBarChart
                  dataset={monthlyReports}
                  yAxis={updatedYAxis}
                  series={[{ dataKey: 'points', fill: '#8884d8' }]}
                  layout={{ width: 500, height: 400 }}
                  chartSetting={chartSetting}
                />
              )}
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} md={12}>
            <Typography gutterBottom variant="h5" component="div">
              Note: Allern platform has a unique assessment mechanism designed particularly for grades 1 to 5 to create
              a learning and understanding path for them in supplementary skillsets. We provide a personalized learning
              development program to the child using traditional printed books with content that is curated per the
              grade. The platform then combines this with a digital measurement tool that can help identify the
              strengths and interest areas of a child, and in turn increase the pace of development of a child.
            </Typography>
          </Grid> */}
        </Grid>
      </Container>
      <br />
      <Button
        variant="contained"
        startIcon={<Iconify icon="mdi:download-outline" />}
        sx={{ marginLeft: '700px' }}
        onClick={downloadPDF}
      >
        Download
      </Button>
      {/* <PersonalAnalytic progressReport={progressReport} /> */}
    </>
  );
};

export default StudentReport;
