import { Helmet } from 'react-helmet-async';

import { useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, TextField, alertTitleClasses } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaArrowLeft } from 'react-icons/fa';
import Iconify from '../components/iconify';
import { SerachUser, deleteUsers } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';
import AppTeachersSummary from '../sections/@dashboard/app/AppWidgetSummary';

export default function Activity() {
  const navigate = useNavigate();
  const [phoneNumber, setphoneNumber] = useState('');
  const [Name, setName] = useState('');
  const [userdata, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const handleMobileNumberChange = (e) => {
  //   setphoneNumber(e.target.value);
  // };

  const handleMobileNumberChange = (e) => {
    // Use regex to remove non-numeric characters from the input
    const numericValue = e.target.value.replace(/\D/g, '');
    // Limit the input to a maximum of 10 characters
    const truncatedValue = numericValue.slice(0, 10);
    setphoneNumber(truncatedValue);
  };

  const handlenameChange = (e) => {
    setName(e.target.value);
  };

  const handleGoBack = () => {
    navigate('/dashboard/QuizDashboard');
  };
  const handleRouteTags = () => {
    navigate(`/dashboard/GetQuizByTags`);
  };

  const handleRouteImport = () => {
    navigate(`/dashboard/ImportQuiz`);
  };

  const [isCardVisible, setIsCardVisible] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    setIsLoading(true); // Set loading state to true

    if (!Name && !phoneNumber) {
      // Both name and mobile number are empty; you can handle this case as needed
      console.log('Please enter a name or a mobile number.');
      setIsLoading(false);
      return;
    }

    SerachUser(Name, phoneNumber)
      .then((res) => {
        if (res?.data?.SUCCESS === true) {
          setUserData(res?.data?.Data);
          setIsCardVisible(true);
        } else {
          // console.log('No matching users found.');
          setIsCardVisible(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log('Error:', error);
        setIsLoading(false);
        setIsCardVisible(false);
      });
  };
  const handleDeleteUser = (userId) => {
    setIsLoading(true);
    deleteUsers(userId)
      .then((response) => {
        if (response?.data.SUCCESS === true) {
          // setRemoveUser(response?.data);

          toast.success(response?.data?.Data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response?.data?.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      });
  };

  //   const users = userdata
  //     .filter((item) => item !== null)
  //     .map((item) => {
  //       return {
  //         Name: item?.name,
  //         UserName: item?.userName,
  //         'Email ID': item.email,
  //         Status: item.isActive ? (
  //           <Button variant="contained" color="primary">
  //             Active
  //           </Button>
  //         ) : (
  //           'Inactive'
  //         ),
  //         Action: (
  //           <div>
  //             <FaEdit
  //               style={{ cursor: 'pointer', marginRight: '10px' }}
  //               onClick={() => handleEditUser(item.userId)} // Pass the user ID to the edit handler
  //             />
  //             <FaTrash
  //               style={{ cursor: 'pointer' }}
  //               onClick={() => handleDeleteUser(item.userId)} // Pass the user ID to the delete handler
  //             />
  //           </div>
  //         ),
  //       };
  //     });

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> New Activity </title>
      </Helmet>
      <Container>
        <FaArrowLeft
          style={{
            cursor: 'pointer',
            gap: '2rem',
            height: '40px',
            width: '40px',
            backgroundColor: '#86A8CF',
            borderRadius: '60%',
          }}
          onClick={handleGoBack} // Pass the user ID to the delete handler
        />
        <br />
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', margin: '2px' }}
          >
            <img src="\favicon\R.png" alt="png" height={70} />
            Create new activity
          </Typography>
        </Stack>
        <br />
        <br />
        <Stack direction="row" spacing={3}>
          {/* <Card sx={{ width: 200 }}>
            <AppTeachersSummary title="Teachers Registered" total={reportdata?.teacherRegistered || 0} />
          </Card> */}
          <Card sx={{ width: 400 }} onClick={handleRouteImport} style={{ cursor: 'pointer' }}>
            <AppTeachersSummary color="info" icon={'simple-line-icons:plus'} />
            <Typography variant="h5" style={{ marginTop: '8px' }}>
              {' '}
              {/* Adjust marginTop as needed */}
              Import Quiz
            </Typography>
          </Card>
          {/* <Card sx={{ width: 400 }}>
            <AppTeachersSummary title="Create Quiz" color="success" icon={'mdi:import'} />
          </Card> */}
          <Card sx={{ width: 400 }} onClick={handleRouteTags} style={{ cursor: 'pointer' }}>
            <AppTeachersSummary color="success" icon={'fluent-mdl2:script'} />
            <Typography variant="h5" style={{ marginTop: '8px' }}>
              {' '}
              {/* Adjust marginTop as needed */}
              Get Quiz by tags
            </Typography>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
