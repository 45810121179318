import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, TextField, Typography } from '@mui/material';
import { ProductList } from '../sections/@dashboard/products';
import '../Styles/product.css';
import { getExcel, getReport } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';

export default function ProductsPage() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [uacCode, setUacCode] = useState('');
  const [submittedValue, setSubmittedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const[isUserActivity ,setIsUserActivity]= useState(false);
  const[isUserDetails ,setIsUserDetails]= useState(false);


  const handleMobileNumberChange = (e) => {
    // Use regex to remove non-numeric characters from the input
    const numericValue = e.target.value.replace(/\D/g, '');
    // Limit the input to a maximum of 10 characters
    const truncatedValue = numericValue.slice(0, 10);
    setMobileNumber(truncatedValue);
  };

  const handleUacCodeChange = (e) => {
    setUacCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    getReport(mobileNumber, uacCode)
      .then((res) => {
       if (res?.data?.status === "SUCCESS") {
        
        setIsLoading(false);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT
      });
        setSubmittedValue(res?.data?.userDetails);
       }
       else{

        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT
      });
        setIsLoading(false);
       }
      })
      .catch((error) => {});
  };


  const handleDownload=()=>{
    setIsLoading(true);

    getExcel(mobileNumber, uacCode, isUserActivity, isUserDetails)
      .then((res) => {
       if (res?.data?.status === "SUCCESS") {
        
        setIsLoading(false);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT
      });
       
       }
       else{
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT
      });
        setIsLoading(false);
       }
      })
      .catch((error) => {});
  }
  const renderProductList = () => {
    if (submittedValue) {
      return <ProductList products={submittedValue} />;
    }
    return null;
  };

  return (
    <>
    {isLoading && <Loader />}
    <ToastContainer/>
      <Helmet>
        <title> Dashboard: Reports </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Reports
        </Typography>

        {/* Checkboxes */}
        <Stack direction="row" justifyContent="flex-end">
          <label htmlFor="userActive" style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="userActive"
              // checked={userActive}
              onChange={()=>{setIsUserActivity((prev)=>!prev)}}
              style={{ marginRight: '3px' }}
            />
            User Active
          </label>
          <label htmlFor="userDetails" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="userDetails"
              // checked={userDetails}
              onChange={()=>{setIsUserDetails((prev)=>!prev)}}
              // style={{ marginRight: '3px' }}
            />
            User Details
          </label>
          <br />
          <button type="submit" style={{ marginLeft: '10px', width: '120px', height: '30px' }}
          onClick={handleDownload}
          >
            Download
          </button>
        </Stack>

        
        <form onSubmit={handleSubmit}>
          

          <TextField
            name="mobileNumber"
            label="Mobile No"
            style={{ width: '260px', marginRight: '10px' }}
            onChange={handleMobileNumberChange}
            value={mobileNumber}
          />
          <TextField
            name="UACCode"
            label="UAC Code"
            style={{ width: '260px', marginRight: '10px' }}
            onChange={handleUacCodeChange}
          />

          <button type="submit" style={{ width: '120px', height: '40px' }}>
            Show
          </button>
        </form>

        {/* Conditionally render the product list */}
        {renderProductList()}
      </Container>
    </>
  );
}
