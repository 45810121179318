import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import '../Styles/product.css';
import { toast } from 'react-toastify';
import Loader from '../components/common/Loader';

import { CreatenewUac } from '../Servies/AdmiServices';

export default function CreateDemoUAC() {
  const [SchoolName, setSchoolName] = useState('');
  const [uacCount, setUacCount] = useState('');
  const [randomString, setRandomString] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const alphabets = 'Z'.split(''); // Array of alphabets

  const handleAlphabetChange = (event) => {
    setRandomString(event.target.value);
  };

  const handleNameChange = (e) => {
    setSchoolName(e.target.value);
  };

  const handleUacCodeChange = (e) => {
    setUacCount(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);
    CreatenewUac(SchoolName, uacCount, randomString)
      .then((res) => {
        // if (res?.data.success === true) {
        // navigate(-1);
        setShowLoader(false);
        toast.success('Data Save Successfully');
        // error("ERROR ",response?.message);
        navigate('/dashboard/ManageUAC');
        // }
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  const handlepage = () => {
    navigate(-1);
  };

  return (
    <>
      {showLoader && <Loader />}

      <Helmet>
        <title> Dashboard: Manage UAC</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5, marginRight: '10px' }}>
          Manage UAC
        </Typography>

        {/* Checkboxes */}

        {/* Input fields and search button */}
        <form onSubmit={handleSubmit}>
          <TextField
            name="Text"
            label="School Name"
            style={{ width: '260px', margin: '20px' }}
            onChange={handleNameChange}
          />
          <TextField
            name="UAC Count"
            label="UAC Count"
            style={{ width: '260px', margin: '20px' }}
            onChange={handleUacCodeChange}
          />
          <FormControl sx={{ width: '260px', margin: '20px' }}>
            <InputLabel id="demo-multiple-name-label">Select Alphabet</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              value={randomString}
              onChange={handleAlphabetChange}
              label="Select Alphabet"
            >
              <MenuItem value="">Select Alphabet</MenuItem>
              {alphabets.map((alphabet) => (
                <MenuItem key={alphabet} value={alphabet}>
                  {alphabet}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <Button type="submit" variant="contained" sx={{ width: '120px', textAlign: 'center' }}>
              Show
            </Button>

            <Button
              type="button"
              variant="contained"
              sx={{
                width: '120px',
                textAlign: 'center',
                marginLeft: '1rem',
                backgroundColor: 'white',
                color: 'black',
              }}
              onClick={handlepage}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Container>
    </>
  );
}
