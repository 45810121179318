import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { FaArrowLeft, FaEdit, FaTrashAlt } from 'react-icons/fa';

import { Navigate, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

import {
  getQuestionBank,
  postQuiz,
  addQuizImport,
  getAllChapterDetails,
  getAllCourseDetails,
  getAllSubjectDetails,
} from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Iconify from '../components/iconify';

export default function ImportQuiz() {
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [questionBank, setQuestionBank] = useState();
  const [questionValues, setQuestionValues] = useState();
  const [numberOfQuestions, setNumberOfQuestions] = useState();
  const [scheduleDateTime, setScheduleDateTime] = useState('');

  const [quizId, setQuizId] = useState('');
  const [quizQuestionTimeInSeconds, setQuizQuestionTimeInSeconds] = useState('');
  const [tableData, setTableData] = useState([]);
  const [questionTableData, setQuestionTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [quizName, setQuizName] = React.useState();
  const [youtubeLive, SetYoutubeLive] = React.useState();
  const [isQuizManual, setIsQuizManual] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [selectedChapter, setSelectedChapter] = React.useState();
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [Courses, setCourses] = useState([]);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate(); // Import useNavigate hook

  const fileInputRef = useRef(null);

  console.log('check quizId', quizId);

  const headers = ['SrNO', 'content', 'Status', 'Action'];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleRadioChange = () => {
    setIsQuizManual((prev) => !prev); // Toggle the state between true and false
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlenameChange = (event) => {
    setQuizName(event.target.value);
  };

  const handleyoutubeChange = (event) => {
    SetYoutubeLive(event.target.value);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };
  const handleGoBack = () => {
    Navigate('/dashboard/QuizDashboard');
  };

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    setSelectedDate(dateValue);
    // Perform any actions you want when the date is changed
  };

  const handleTimeChange = (event) => {
    const timeValue = event.target.value;
    setSelectedTime(timeValue);
    // Perform any actions you want when the time is changed
  };

  const handleQuizTimeChange = (event) => {
    setQuizQuestionTimeInSeconds(event.target.value);
  };

  const onSelectCourse = (event) => {
    setSelectedCourse(event?.target?.value);
  };

  const onSelectsubject = (event) => {
    setSelectedSubject(event?.target?.value);
  };

  const onSelectChapter = (event) => {
    setSelectedChapter(event?.target?.value);
  };

  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();

    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourses(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);
      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const fetchChapterData = async (courseId, subjectId) => {
    try {
      const chapterDetails = await getAllChapterDetails(courseId, subjectId);
      const chapterCode = chapterDetails?.data?.map((data) => ({
        chapterName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setChapter(chapterCode);
    } catch (error) {
      console.error('Error fetching chapter details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse && selectedSubject) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      if (selectedCourseObject && selectedSubjectObject) {
        fetchChapterData(selectedCourseObject.courseId, selectedSubjectObject.levelId);
      }
    }
  }, [selectedCourse, selectedSubject, Courses]);

  const handleFileButtonClick = (event) => {
    if (!selectedCourse || !selectedSubject) {
      setIsDialogOpen(true);
    } else {
      fileInputRef.current.click();
    }
  };

  // const handleFormSubmit = () => {
  //   const file = uploadedFile;
  //   if (selectedCourse && selectedSubject && selectedChapter) {
  //     const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
  //     const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
  //     const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === selectedChapter);

  //     if (selectedCourseObject && selectedSubjectObject && selectedChapterObject) {
  //       const courseId = selectedCourseObject.courseId;
  //       const subjectId = selectedSubjectObject.levelId;
  //       const chapterId = selectedChapterObject.levelId;

  //       setIsLoading(true);
  //       // Assuming you have a function like addQuizImport that accepts additional parameters
  //       addQuizImport(courseId, subjectId, chapterId, quizName, file)
  //         .then((response) => {
  //           if (response.data && response.data.success) {
  //             setTableData(response.data);
  //             const quizId = response.data.data?.quizId;
  //             if (quizId) {
  //               setQuizId(quizId);
  //               console.log(quizId);
  //               setIsCardVisible(true);
  //             } else {
  //               console.error('Quiz ID not found in the response data.');
  //               setIsCardVisible(false);
  //             }
  //           } else {
  //             console.error('Unexpected response structure:', response.data);
  //             setIsCardVisible(false);
  //           }

  //           setIsLoading(false);
  //         })
  //         .catch((error) => {
  //           setIsLoading(false);
  //           console.error('AddCourse error:', error);
  //         });
  //     }
  //   }
  // };
  const handleFormSubmit = () => {
    const file = uploadedFile;
    if (selectedCourse && selectedSubject && selectedChapter) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === selectedChapter);

      if (selectedCourseObject && selectedSubjectObject && selectedChapterObject) {
        const courseId = selectedCourseObject.courseId;
        const subjectId = selectedSubjectObject.levelId;
        const chapterId = selectedChapterObject.levelId;

        setIsLoading(true);
        // Assuming you have a function like addQuizImport that accepts additional parameters
        addQuizImport(courseId, subjectId, chapterId, quizName, file)
          .then((response) => {
            if (response.data && response.data.success) {
              setTableData(response.data);
              const quizId = response.data.data?.quizId;
              if (quizId) {
                setQuizId(quizId);
                console.log(quizId);
                setIsCardVisible(true);
              } else {
                console.error('Quiz ID not found in the response data.');
                setIsCardVisible(false);
              }
              // Show success toast here
              toast.success('File uploaded successfully!');
            } else {
              console.error('Unexpected response structure:', response.data);
              setIsCardVisible(false);
            }

            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error('AddCourse error:', error);
          });
      }
    }
  };

  console.log('tabldataa', tableData);
  useEffect(() => {
    console.log(tableData, 'tabledta');
    if (tableData?.data?.questionsData?.length > 0) {
      const tableContent = tableData?.data?.questionsData?.map((question, serialNumber) => {
        if (question?.title?.length === 1) {
          return {
            SrNO: serialNumber + 1,
            content:
              question?.title[0].type === 'latex' ? (
                <BlockMath math={question.title[0].content} />
              ) : (
                <span style={{ fontFamily: question?.title[0].font, fontSize: '20px' }}>
                  {question?.title[0].content}
                </span>
              ),
            Status: question.isActive ? (
              <Button variant="contained" color="primary">
                Active
              </Button>
            ) : (
              ''
            ),
            Action: (
              <div>
                <FaEdit
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  // onClick={() => handleEditUser(question.id)} // Pass the user ID to the edit handler
                />
              </div>
            ),
          };
        }
        if (question?.title?.length > 1) {
          const multipleQuestion = question?.title?.map((titleQuestion, index) => ({
            SrNO: index + 1,
            content:
              titleQuestion.type === 'latex' ? <BlockMath math={titleQuestion.content} /> : titleQuestion.content,
            Status: titleQuestion.isActive ? (
              <Button variant="contained" color="primary">
                Active
              </Button>
            ) : (
              ''
            ),
            Action: (
              <div>
                <FaEdit
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  // onClick={() => handleEditUser(titleQuestion.id)} // Pass the user ID to the edit handler
                />
              </div>
            ),
          }));
          return multipleQuestion;
        }
        return [];
      });

      // Concatenate the arrays generated from mapping through questions with multiple titles
      const concatenatedTableContent = [].concat(...tableContent);
      setQuestionTableData(concatenatedTableContent);
    }
  }, [tableData]);

  const handlePublishQuiz = () => {
    const formattedDateTime = moment(`${selectedDate} ${selectedTime}`, 'YYYY-MM-DD HH:mm').format(
      'DD-MM-YYYY HH:mm:ss'
    );
    postQuiz(quizId, quizQuestionTimeInSeconds, formattedDateTime, quizName, youtubeLive, isQuizManual)
      .then((response) => {
        toast.success('Quiz Published Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setQuestionBank(response?.data);
        console.log('hello', response?.data);
        setIsCardVisible(true);
        // Navigate to quizDashboard page
        navigate('/dashboard/quizDashboard');
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  return (
    <>
      <ToastContainer />

      <Helmet>
        <title> Dashboard: Import Quiz </title>
      </Helmet>

      <Container>
        <FaArrowLeft
          style={{
            cursor: 'pointer',
            gap: '2rem',
            height: '40px',
            width: '40px',
            backgroundColor: '#86A8CF',
            borderRadius: '60%',
          }}
          onClick={handleGoBack} // Pass the user ID to the delete handler
        />
        <br />
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Import Quiz
          </Typography>
          <Button
            className="btn mt-3"
            variant="contained"
            startIcon={<Iconify icon="carbon:catalog-publish" />}
            // onClick={
            //   Navigate('/dashboard/app/manageuser')
            //   }>
            // onClick={handlePublishQuiz}
            onClick={handleClickOpen}
          >
            Schedule Quiz
          </Button>
        </Stack>

        <form>
          <div className="main" style={{ display: 'flex' }}>
            <div className="col-md-3" style={{ display: 'flex', gap: '1rem' }}>
              <div className="col-md-3">
                <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                  <InputLabel id="demo-multiple-name-label">Courses</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={selectedCourse}
                    onChange={(e) => onSelectCourse(e)}
                    input={<OutlinedInput label="Vehicle Model" />}
                    startAdornment={
                      <InputAdornment position="start">
                        {isLoading === true ? <CircularProgress size={15} /> : ''}
                      </InputAdornment>
                    }
                    MenuProps={MenuProps}
                  >
                    {Courses.map((course) => (
                      <MenuItem key={course.courseId} value={course.courseName}>
                        {course.courseName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>{' '}
              <div className="col-md-3" style={{ marginLeft: '10px' }}>
                <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                  <InputLabel id="demo-multiple-name-label">Subject</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={selectedSubject}
                    onChange={onSelectsubject}
                    input={<OutlinedInput label="Vehicle Model" />}
                    startAdornment={
                      <InputAdornment position="start">
                        {isLoading === true ? <CircularProgress size={15} /> : ''}
                      </InputAdornment>
                    }
                    MenuProps={MenuProps}
                  >
                    {subject.map((subject) => (
                      <MenuItem key={subject.levelId} value={subject.subjectName}>
                        {subject.subjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3" style={{ marginLeft: '10px' }}>
                <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                  <InputLabel id="demo-multiple-name-label">Chapter</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={selectedChapter}
                    onChange={onSelectChapter}
                    input={<OutlinedInput label="Vehicle Model" />}
                    startAdornment={
                      <InputAdornment position="start">
                        {isLoading === true ? <CircularProgress size={15} /> : ''}
                      </InputAdornment>
                    }
                    MenuProps={MenuProps}
                  >
                    {chapter.map((chapter) => (
                      <MenuItem key={chapter.levelId} value={chapter.chapterName}>
                        {chapter.chapterName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <br />
              <div className="Tgname" style={{ display: 'block', bottom: '30px', marginright: '-1000px' }}>
                <TextField
                  name="Name"
                  label="Name"
                  style={{ width: '260px', marginRight: '10px' }}
                  onChange={handlenameChange}
                />
              </div>
              <CardContent
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {uploadedFile ? (
                    <>
                      <Typography variant="body1" style={{ marginLeft: '10px' }}>
                        {uploadedFile.name}
                      </Typography>
                      <IconButton color="secondary" onClick={() => setUploadedFile(null)}>
                        <FaTrashAlt />
                      </IconButton>
                      <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                        Upload
                      </Button>
                    </>
                  ) : (
                    <>
                      <label htmlFor="containedfile">
                        <Button variant="contained" color="primary" component="span" id="contained-button-file">
                          Import
                        </Button>
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx,.xls,.xlsx"
                          style={{ display: 'none' }}
                          id="containedfile"
                          onChange={handleFileUpload}
                          ref={fileInputRef}
                        />
                      </label>
                    </>
                  )}
                </div>
              </CardContent>
            </div>
          </div>
        </form>
        <br />
        {isCardVisible && (
          <Card>
            <div style={{ alignItems: 'center' }}>
              <TableData
                // data={sortedData.length === 0 ? questionBankData : sortedData}
                data={questionTableData}
                headers={headers}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </div>
          </Card>
        )}
        <Dialog fullWidth={fullWidth} open={open} onClose={handleClose}>
          {' '}
          <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>Publish Quiz</DialogTitle>
          <br />
          <DialogContent>
            <DialogContentText>Enter Quiz Name </DialogContentText>
            <br />
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <TextField
                name="Name"
                label="Enter Quiz Name"
                style={{ width: '260px', marginRight: '10px' }}
                onChange={handlenameChange}
              />
            </Box>

            <br />
            <br />
            <DialogContentText>Enter Youtube Live Link </DialogContentText>
            <br />
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <TextField
                name="Name"
                label="YouTube Link"
                style={{ width: '260px', marginRight: '10px' }}
                onChange={handleyoutubeChange}
              />
            </Box>
            <br />
            <br />

            <DialogContentText>Select Date & Time.</DialogContentText>
            <br />
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              {' '}
              <div style={{ display: 'flex', gap: '2rem' }}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                <DateTimePicker
                  label="Controlled picker"
                  value={scheduleDateTime}
                  onChange={(newValue) => setScheduleDateTime(newValue)}
                  format="DD-MM-YYYY HH:mm:ss"
                />
              </DemoContainer>
            </LocalizationProvider> */}
                <TextField
                  label="Select Date"
                  type="date"
                  value={selectedDate} // Format date as "YYYY-MM-DD"
                  onChange={(e) => handleDateChange(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className="time">
                  <input
                    type="time"
                    value={selectedTime}
                    onChange={(e) => handleTimeChange(e)}
                    style={{ width: '100px', height: '55px' }}
                  />
                  <p>Selected Time: {selectedTime}</p>
                </div>
              </div>
            </Box>

            <br />
            <br />

            <DialogContentText>Select Question Display Time.</DialogContentText>
            <br />

            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <FormControl sx={{ width: '230px' }}>
                <InputLabel id="demo-simple-select-autowidth-label">Quiz Time</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={quizQuestionTimeInSeconds}
                  onChange={handleQuizTimeChange}
                  autoWidth
                  label="Quiz Time"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={5}>5 Second</MenuItem>
                  <MenuItem value={10}>10 second</MenuItem>
                  <MenuItem value={20}>20 second</MenuItem>
                  <MenuItem value={30}>30 Second</MenuItem>
                  <MenuItem value={45}>45 Second</MenuItem>
                  <MenuItem value={60}>1 Minute</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <br />

            <DialogContentText>Is Quiz Automatic Or Manual.</DialogContentText>
            <br />

            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Switch
                    checked={isQuizManual}
                    onChange={handleRadioChange}
                    //  onChange={handleFullWidthChange}
                  />
                }
                label="Is Quiz Manual"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn mt-3"
              variant="contained"
              startIcon={<Iconify icon="carbon:catalog-publish" />}
              // onClick={
              //   Navigate('/dashboard/app/manageuser')
              //   }>
              // onClick={handlePublishQuiz}
              onClick={handlePublishQuiz}
            >
              Publish Quiz
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
