import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Stack, TextField, Typography } from '@mui/material';
import '../Styles/product.css';
import { FaPlus, FaDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { getAllTEClient, getUacCodeForClient } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';


export default function TeachersEdgeUAC() {
  const headers = ['Client Id', 'Client Name', 'Client Number', 'School Address', 'School Email', 'School Name', 'Action']
  const navigate = useNavigate();
  const [UACData, setUACData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const handleSearch = (event) => {
    event.preventDefault();
    const searchData = UACData.filter((client) => {
      return client.client_number.includes(search) ||
        client.client_name.toLowerCase().includes(search.toLowerCase()) ||
        client.school_name.toLowerCase().includes(search.toLowerCase())
    })

    setFilteredData(searchData);
  };

  const getClientData = () => {
    setIsLoading(true);
    try {
      getAllTEClient().then((resposne) => {
        if (resposne.data && resposne?.data?.status === 'SUCCESS') {
          console.log(resposne.data)
          setUACData(resposne.data.response)
          setFilteredData(resposne.data.response)

        } else {
          toast.error("Something went wrong!")
        }
      })
      setIsLoading(false)
    } catch (error) {
      toast.error("Something went wrong!")
      setIsLoading(false)
    }
  }


  useEffect(() => {
    getClientData();
  }, [])


  const tableData = filteredData.map((item) => {
    return {
      'Client Id': item.client_id,
      'Client Name': item.client_name,
      'Client Number': item.client_number,
      'School Address': item.school_address,
      'School Email': item.school_email,
      'School Name': item.school_name,
      Action: (<>
        <FaPlus style={{ cursor: 'pointer', }} title='Create UAC' onClick={() => { onCreate(item.client_id) }} />
        <FaDownload style={{ cursor: 'pointer', marginLeft: 10 }} title='Download UAC' onClick={() => handleDownload(item.client_id)}
        />
      </>
      ),
    }
  })


  const onCreate = (clientId) => {
    navigate('/dashboard/CreateUacForClient', { state: clientId });
  };

  const handleDownload = async (clientId) => {
    try {
      const res = await getUacCodeForClient(clientId)
      const jsonData = res?.data?.response;
      if (!jsonData) {
        console.error('Response data is empty.');
        toast.error("No UAC Data found.")
        return;
      }

      const filteredData = jsonData.map(item => {
        return {
          "Client Name": item.client_name,
          "Client Id": item.client_id,
          "Contact Number": item.contact_number,
          "Course Display Name": item.course_display_name,
          "Subject Name": item.subject_name,
          "UA Code": item.ua_code,
          "Validity End Date": item.validity_end_date,
          "Max lic count": item.max_lic_count,
          "Used lic count": item.used_lic_count,
        };
      });

      const fileName = `${jsonData[0].client_name}.xlsx`;
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(filteredData), 'Data');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(excelBuffer)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    } catch (err) {
      console.error('Error downloading file:', err);
    }
  };


  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i += 1) {
      view[i] = s.charCodeAt(i) % 256;
    }
    return buf;
  }


  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Dashboard:TeachersEdge UAC </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Teachers Edge UAC
          </Typography>
          <div>
            <Button variant="contained" style={{ marginLeft: 5 }} onClick={() => { navigate('/dashboard/CreateClient'); }}>
              Add New Client
            </Button>
          </div>
        </Stack>

        <form onSubmit={handleSearch} style={{ marginBottom: '20px' }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField name="search" label="Search"
              variant="outlined" style={{ width: 320 }}
              onChange={(e) => { setSearch(e.target.value) }}
            />
            <Button type="submit" variant="contained" style={{ height: '40px' }}> Search </Button>
          </Stack>
        </form>

        <TableData data={tableData} headers={headers} rowsPerPageOptions={[10, 20,30,filteredData.length]} />
      </Container>
    </>
  );
};








