import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

// import '../Styles/product.css';
import { FaEdit, FaSearch } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../Styles/play.css';

import { ToastContainer, toast } from 'react-toastify';
import 'katex/dist/katex.min.css';

import { BlockMath } from 'react-katex';
import { getQuizTags, getQuestionBank, postQuiz } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Iconify from '../components/iconify';
import Loader from '../components/common/Loader';

export default function GetQuizByTags() {
  const navigate = useNavigate();
  const [quizTags, setQuizTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [NotificationData, setNotificationData] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [questionBank, setQuestionBank] = useState();
  const [questionValues, setQuestionValues] = useState();
  const [modal, setModal] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState();
  const [scheduleDateTime, setScheduleDateTime] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [youtubeLive, SetYoutubeLive] = React.useState();

  const [quizId, setQuizId] = useState('');
  const [quizQuestionTimeInSeconds, setQuizQuestionTimeInSeconds] = useState('');
  const [tableData, setTableData] = useState([]);
  const [questionTableData, setQuestionTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [quizName, setQuizName] = React.useState();
  const [isQuizManual, setIsQuizManual] = useState(false);

  console.log('check quizId', quizId);
  // const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));

  // const [searchValue, setSearchValue] = useState('');
  const [selectTags, setSelectTags] = useState('');
  const [showAllData, setShowAllData] = useState(true);
  const headers = ['SrNO', 'content', 'Status', 'Action'];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleRadioChange = () => {
    setIsQuizManual((prev) => !prev); // Toggle the state between true and false
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleyoutubeChange = (event) => {
    SetYoutubeLive(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlenameChange = (event) => {
    setQuizName(event.target.value);
  };
  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    setSelectedDate(dateValue);
    // Perform any actions you want when the date is changed
  };

  const handleTimeChange = (event) => {
    const timeValue = event.target.value;
    setSelectedTime(timeValue);
    // Perform any actions you want when the time is changed
  };

  // const dummyTags = [
  //   {
  //     tagId: 2,
  //     tag: 'english',
  //   },
  //   {
  //     tagId: 1,
  //     tag: 'grade10_physics',
  //   },
  // ];
  // const handleDateChange = (data) => {
  //   const formattedDate = moment(data).format('DD-MM-YYYY HH:mm:ss.SSS');
  //   setScheduleDateTime(formattedDate);

  //   // Perform any actions you want when the date is changed
  // };

  const handleQuizTimeChange = (event) => {
    setQuizQuestionTimeInSeconds(event.target.value);
  };

  const onSelectTags = (event) => {
    // const selectedSubjectObject = subject.find((subject) => subject.subjectName === event.target.value);
    setSelectTags(event.target.value);
    console.log(event.target.value, 'check tag');
  };

  const handleNoOfQuesChange = (event) => {
    setNumberOfQuestions(event.target.value);
  };

  const handleChangeTags = async () => {
    try {
      const dummyTags = await getQuizTags();
      const tagsCode = dummyTags?.data?.map((data) => ({
        tagName: data?.tag,
        tagId: data?.tagId,
      }));

      setQuizTags(tagsCode);
      console.log(tagsCode, 'heyy');
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    handleChangeTags();
  }, []);

  const handleEditUser = (id) => {
    navigate(`/dashboard/ManageNotification?id=${id}`);
  };

  const handlesubmit = () => {
    const data = {
      numberOfQuestions,
      tagId: selectTags ? selectTags.tagId : '',
    };
    setIsLoading(true);
    getQuestionBank(data)
      .then((response) => {
        if (response.data !== null) {
          // setQuestionBank(response.data);
          setTableData(response.data);
          // console.log('check content', response.data);
          setIsCardVisible(true);
          setQuizId(response?.data?.quizId);
          setIsLoading(false);
        } else {
          setIsCardVisible(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };
  useEffect(() => {
    console.log(tableData, 'tabledta');
    if (tableData?.questionsData?.length > 0) {
      const tableContent = tableData?.questionsData?.map((question, serialNumber) => {
        if (question?.title?.length === 1) {
          // Concatenate titles into a single line
          const concatenatedTitles = question.title.reduce((acc, curr) => {
            if (curr.type === 'latex') {
              acc += `<BlockMath math="${curr.content}" />`; // For latex type
            } else if (curr.type === 'image') {
              acc += `<img src="${curr.content}" alt="Image" />`; // For img type
            } else {
              acc += `<span style="font-family: ${curr.font}; font-size: 20px;">${curr.content}</span>`; // For text type
            }
            return acc;
          }, '');
          return {
            SrNO: serialNumber + 1,
            content: <span dangerouslySetInnerHTML={{ __html: concatenatedTitles }} />, // Render as HTML

            Status: question.isActive ? (
              <Button variant="contained" color="primary">
                Active
              </Button>
            ) : (
              ''
            ),
            Action: (
              <div>
                <FaEdit
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  // onClick={() => handleEditUser(question.id)} // Pass the user ID to the edit handler
                />
              </div>
            ),
          };
        }
        // Handle single title object case
        const title = question.title[0];
        return {
          SrNO: serialNumber + 1,
          content:
            title.type === 'latex' ? (
              <BlockMath math={title.content} />
            ) : (
              <span style={{ fontFamily: title.font, fontSize: '20px' }}>{title.content}</span>
            ),
          Status: question.isActive ? (
            <Button variant="contained" color="primary">
              Active
            </Button>
          ) : (
            ''
          ),
          Action: (
            <div>
              <FaEdit
                style={{ cursor: 'pointer', marginRight: '10px' }}
                // onClick={() => handleEditUser(question.id)} // Pass the user ID to the edit handler
              />
            </div>
          ),
        };
      });

      // Concatenate the arrays generated from mapping through questions with multiple titles
      const concatenatedTableContent = [].concat(...tableContent);
      setQuestionTableData(concatenatedTableContent);
    }
  }, [tableData]);

  const handlePublishQuiz = () => {
    const formattedDateTime = moment(`${selectedDate} ${selectedTime}`, 'YYYY-MM-DD HH:mm').format(
      'DD-MM-YYYY HH:mm:ss'
    );

    setIsLoading(true);
    postQuiz(quizId, quizQuestionTimeInSeconds, formattedDateTime, quizName, youtubeLive, isQuizManual)
      .then((response) => {
        setQuestionBank(response?.data);
        console.log('hello', setQuestionBank);
        toast.success('Quiz Published Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsCardVisible(true);
        setIsLoading(false);
        navigate('/dashboard/quizDashboard');
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  // const serialNumber = 1;
  // const questionBankData = Array.isArray(questionBank)
  //   ? questionBank.map((item) => {
  //       return {
  //         SrNO: serialNumber + 1,
  //         content: item?.content,
  //         Status: item.isActive ? (
  //           <Button variant="contained" color="primary">
  //             Active
  //           </Button>
  //         ) : (
  //           ''
  //         ),
  //         Action: (
  //           <div>
  //             <FaEdit
  //               style={{ cursor: 'pointer', marginRight: '10px' }}
  //               onClick={() => handleEditUser(item.id)} // Pass the user ID to the edit handler
  //             />
  //           </div>
  //         ),
  //       };
  //     })
  //   : [];
  // const [sortedData, setSortedData] = useState(NotificationData);

  // const handleSearchInputChange = (searchVal) => {
  //   const searchedValue = searchVal.target.value.toLowerCase();

  //   const filteredRows = NotificationData.filter((row) => {
  //     // Use a return statement here to return the result of .map()
  //     return row !== null && row.notificationBody.toLowerCase().includes(searchedValue);
  //   });

  //   setSortedData(filteredRows);
  // };

  return (
    <>
      {isLoading && <Loader />}

      <ToastContainer />

      <Helmet>
        <title> Dashboard: Notifications </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Get Question Bank
          </Typography>
          <Button
            className="btn mt-3"
            variant="contained"
            startIcon={<Iconify icon="carbon:catalog-publish" />}
            // onClick={
            //   Navigate('/dashboard/app/manageuser')
            //   }>
            // onClick={handlePublishQuiz}
            onClick={handleClickOpen}
          >
            Schedule Quiz
          </Button>
        </Stack>

        <form>
          <div className="main" style={{ display: 'flex' }}>
            <div className="col-md-3" style={{ display: 'flex', gap: '1rem' }}>
              <FormControl sx={{ width: 270 }}>
                <InputLabel id="demo-multiple-name-label">Select Tag</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectTags}
                  onChange={(e) => {
                    onSelectTags(e);
                  }}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {quizTags.map((quizTags) => (
                    <MenuItem key={quizTags.tagId} value={quizTags}>
                      {quizTags.tagName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                name="number"
                label="No of Questions"
                style={{ width: '150px', marginRight: '10px' }}
                onChange={handleNoOfQuesChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handlesubmit}
                sx={{ width: '120px', height: '40px', marginTop: '10px', textAlign: 'center' }}
              >
                Search
              </Button>
            </div>
          </div>
        </form>
        <br />
        {isCardVisible && (
          <Card>
            <div style={{ alignItems: 'center' }}>
              <TableData
                // data={sortedData.length === 0 ? questionBankData : sortedData}
                data={questionTableData}
                headers={headers}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </div>
          </Card>
        )}
        <Dialog fullWidth={fullWidth} open={open} onClose={handleClose}>
          {' '}
          <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>Publish Quiz</DialogTitle>
          <br />
          <DialogContent>
            <DialogContentText>Enter Quiz Name </DialogContentText>
            <br />
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <TextField
                name="Name"
                label="Enter Quiz Name"
                style={{ width: '260px', marginRight: '10px' }}
                onChange={handlenameChange}
              />
            </Box>

            <br />
            <br />
            <DialogContentText>Enter Youtube Live Link </DialogContentText>
            <br />
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <TextField
                name="Name"
                label="YouTube Link"
                style={{ width: '260px', marginRight: '10px' }}
                onChange={handleyoutubeChange}
              />
            </Box>
            <br />
            <br />

            <DialogContentText>Select Date & Time.</DialogContentText>
            <br />
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              {' '}
              <div style={{ display: 'flex', gap: '2rem' }}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                <DateTimePicker
                  label="Controlled picker"
                  value={scheduleDateTime}
                  onChange={(newValue) => setScheduleDateTime(newValue)}
                  format="DD-MM-YYYY HH:mm:ss"
                />
              </DemoContainer>
            </LocalizationProvider> */}
                <TextField
                  label="Select Date"
                  type="date"
                  value={selectedDate}
                  onChange={(e) => handleDateChange(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className="time">
                  <input
                    type="time"
                    value={selectedTime}
                    onChange={(e) => handleTimeChange(e)}
                    style={{ width: '100px', height: '55px' }}
                  />
                  <p>Selected Time: {selectedTime}</p>
                </div>
              </div>
            </Box>

            <br />
            <br />

            <DialogContentText>Select Question Display Time.</DialogContentText>
            <br />

            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <FormControl sx={{ width: '230px' }}>
                <InputLabel id="demo-simple-select-autowidth-label">Quiz Time</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={quizQuestionTimeInSeconds}
                  onChange={handleQuizTimeChange}
                  autoWidth
                  label="Quiz Time"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={5}>5 Second</MenuItem>
                  <MenuItem value={10}>10 second</MenuItem>
                  <MenuItem value={20}>20 second</MenuItem>
                  <MenuItem value={30}>30 Second</MenuItem>
                  <MenuItem value={45}>45 Second</MenuItem>
                  <MenuItem value={60}>1 Minute</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <br />

            <DialogContentText>Is Quiz Automatic Or Manual.</DialogContentText>
            <br />

            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Switch
                    checked={isQuizManual}
                    onChange={handleRadioChange}
                    //  onChange={handleFullWidthChange}
                  />
                }
                label="Is Quiz Manual"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn mt-3"
              variant="contained"
              startIcon={<Iconify icon="carbon:catalog-publish" />}
              // onClick={
              //   Navigate('/dashboard/app/manageuser')
              //   }>
              // onClick={handlePublishQuiz}
              onClick={handlePublishQuiz}
            >
              Publish Quiz
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
