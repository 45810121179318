import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Stack, Typography } from '@mui/material';
import '../Styles/product.css';

import { useNavigate } from 'react-router-dom';

// import { downloadExcelFile } from '../utils/dwonloadExcelFile';

export default function StudentUAC() {
  const navigate = useNavigate();

  const onCreate = () => {
    navigate('/dashboard/CreateStudentUAC');
  };

  return (
    <>
       <ToastContainer />
      <Helmet>
        <title> Dashboard: Student UAC </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Student UAC
          </Typography>
          <Button variant="contained" onClick={onCreate}>
          StudentUAC
          </Button>
        </Stack>

        {/* Render the table component */}
        {/* <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} /> */}
      </Container>
    </>
  );
}
