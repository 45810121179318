import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  Button,
  Switch,
  TextField,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import '../Styles/product.css';
import { editNotifcations, getAllCourseDetails, getAllSubjectDetails, sendnotification } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';

export default function ManageNotification() {
  const navigate = useNavigate();
  const [Courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [showAllData, setShowAllData] = useState({});
  const [selectedApp, setSelectedApp] = useState('True'); // or any other default value
  const [schedule, setSchedule] = useState('');

  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onSelectCourse = (event) => {
    const selectedCourseObject = Courses.find((course) => course.courseName === event.target.value);
    setSelectedCourse(selectedCourseObject);

    if (selectedCourseObject) {
      fetchSubjectData(selectedCourseObject.courseId);
    }
  };

  const onSelectSubject = (event) => {
    const selectedSubjectObject = subjects.find((subject) => subject.subjectName === event.target.value);
    setSelectedSubject(selectedSubjectObject);
  };

  const onSelectApp = (event) => {
    setSelectedApp(event?.target?.value);
  };

  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();
    const courseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));
    setCourses(courseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  useEffect(() => {
    if (id) {
      editNotifcations(id)
        .then((response) => {
          setShowAllData(response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [id]);

  const handlepage = () => {
    navigate(-1);
  };

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);

      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubjects(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  const data = {
    courseId: selectedCourse ? selectedCourse.courseId : '',
    subjectId: selectedSubject ? selectedSubject.levelId : '',
    isTopic: selectedApp,
    notificationTitle: title,
    notificationBody: message,
    userId: '',
    action: schedule,
  };

  const handleShow = () => {
    sendnotification(data)
      .then((response) => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Notifications </title>
      </Helmet>
      {isLoading && <Loader />}
      <ToastContainer />
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Manage Notification
        </Typography>
        <Card sx={{ padding: 3, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
          <CardContent>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size={'small'}>
                    <InputLabel id="course-label">Courses</InputLabel>
                    <Select
                      labelId="course-label"
                      id="course-select"
                      value={
                        selectedApp === 'True' ? showAllData?.courseId : selectedCourse ? selectedCourse.courseName : ''
                      }
                      onChange={onSelectCourse}
                      input={<OutlinedInput label="Courses" />}
                      startAdornment={
                        <InputAdornment position="start">
                          {isLoading ? <CircularProgress size={15} /> : ''}
                        </InputAdornment>
                      }
                      MenuProps={MenuProps}
                      disabled={selectedApp === 'True' || !!showAllData?.courseId}
                    >
                      {Courses.map((course) => (
                        <MenuItem key={course.courseId} value={course.courseName}>
                          {course.courseName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size={'small'}>
                    <InputLabel id="subject-label">Level</InputLabel>
                    <Select
                      labelId="subject-label"
                      id="subject-select"
                      value={selectedApp === 'True' ? '' : selectedSubject ? selectedSubject.subjectName : ''}
                      onChange={onSelectSubject}
                      input={<OutlinedInput label="Level" />}
                      startAdornment={
                        <InputAdornment position="start">
                          {isLoading ? <CircularProgress size={15} /> : ''}
                        </InputAdornment>
                      }
                      MenuProps={MenuProps}
                      disabled={selectedApp === 'True' || !!showAllData?.subject_id}
                    >
                      {subjects.map((subject) => (
                        <MenuItem key={subject.levelId} value={subject.subjectName}>
                          {subject.subjectName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size={'small'}>
                    <InputLabel id="app-label">Notification Topic</InputLabel>
                    <Select
                      labelId="app-label"
                      id="app-select"
                      value={selectedApp || (showAllData.notificationType === 'TOPIC' ? 'True' : 'False')}
                      onChange={onSelectApp}
                      input={<OutlinedInput label="Notification Topic" />}
                      startAdornment={
                        <InputAdornment position="start">
                          {isLoading ? <CircularProgress size={15} /> : ''}
                        </InputAdornment>
                      }
                      MenuProps={MenuProps}
                      disabled={!!showAllData.notificationType}
                    >
                      <MenuItem value="True">TOPIC</MenuItem>
                      <MenuItem value="False">NON_TOPIC</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size={'small'}>
                    <InputLabel id="app-label">Action</InputLabel>
                    <Select
                      labelId="app-label"
                      id="app-select"
                      value={selectedApp || showAllData.action === 'TOPIC'}
                      onChange={onSelectApp}
                      input={<OutlinedInput label="Notification Topic" />}
                      startAdornment={
                        <InputAdornment position="start">
                          {isLoading ? <CircularProgress size={15} /> : ''}
                        </InputAdornment>
                      }
                      MenuProps={MenuProps}
                      disabled={!!showAllData.action}
                    >
                      <MenuItem value="True">Schedule</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Title"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    value={showAllData?.notificationTitle || title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="Body"
                    label="Body"
                    variant="outlined"
                    fullWidth
                    value={showAllData?.notificationBody || message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
              </Grid>

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShow}
                  sx={{ width: '150px', alignSelf: 'center', marginLeft: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: '120px',
                    textAlign: 'center',
                    marginLeft: '2rem',
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                  onClick={handlepage}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
