import { Helmet } from 'react-helmet-async';

import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, TextField, alertTitleClasses } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Iconify from '../components/iconify';
import { getClients } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function ClientRegister() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const headers = ['ClientId', 'ClientName', 'ClientNumber', 'SchoolName', 'SchoolAddress', 'SchoolEmail'];

  const handleNewUserClick = () => {
    navigate('/dashboard/CreateClient');
  };

  const fetchAllData = () => {
    getClients()
      .then((res) => {
        setClientData(res?.data);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {});
  };

  console.log(clientData);
  useEffect(() => {
    fetchAllData();
  }, []);

  const clients = clientData.map((item) => {
    return {
      ClientId: item?.clientId,
      ClientName: item?.clientName,
      ClientNumber: item.clientNumber,
      SchoolName: item.schoolName,
      SchoolAddress: item.schoolAddress,
      SchoolEmail: item.schoolEmail,
    };
  });

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Client </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Client
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            // onClick={
            //   Navigate('/dashboard/app/manageuser')
            //   }>
            onClick={handleNewUserClick}
          >
            Create Client
          </Button>
        </Stack>

        <br />

        <TableData data={clients} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
      </Container>
    </>
  );
}
