import { Helmet } from 'react-helmet-async';

import React, { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  alertTitleClasses,
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
// import JSZip from 'jszip';
// import XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { FaEdit, FaClipboardList, FaTrash } from 'react-icons/fa';
import { IconName } from 'react-icons/ai';

import {
  getAllChapterDetails,
  getAllCourseDetails,
  getAllSubjectDetails,
  getStudentReportCard,
} from '../Servies/AdmiServices';

import Iconify from '../components/iconify';

import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function StudentList() {
  const navigate = useNavigate();
  const schoolId = localStorage.getItem('schoolId');
  const [Courses, setCourese] = useState([]);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedChapter, setSelectedChapter] = React.useState('');
  const [userReportCard, setUserReportCard] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [filterEntries, setFilterEntries] = useState([{ id: 1, course: '', subject: '', chapter: '' }]);
  const [filterIds, setFilterIds] = useState(0); // State variable to keep track of filter IDs
  const [isCardVisible, setIsCardVisible] = useState(false);
  // Inside your component function
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [selectedChapterId, setSelectedChapterId] = useState('');
  const headers = ['student Name', 'Mobile No', 'Action'];
  const [newCourseEntry, setNewCourseEntry] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const mockData = [
    { studentName: 'John Doe', Grade: '9', Div: 'A' },
    { studentName: 'Jane Smith', Grade: '10', Div: 'B' },
    // Add more mock data entries as needed
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // const onSelectCourse = (event) => {
  //   setSelectedCourse(event?.target?.value);
  //   const selectedCourseObject = Courses.find((course) => course.courseName === event.target.value);
  //   if (selectedCourseObject) {
  //     setSelectedCourseId(selectedCourseObject.courseId);
  //   }
  // };

  // const onSelectsubject = (event) => {
  //   setSelectedSubject(event?.target?.value);
  //   const selectedSubjectObject = subject.find((subject) => subject.subjectName === event.target.value);
  //   if (selectedSubjectObject) {
  //     setSelectedSubjectId(selectedSubjectObject.levelId);
  //   }
  // };

  // const onSelectChapter = (event) => {
  //   setSelectedChapter(event?.target?.value);
  //   const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === event.target.value);
  //   if (selectedChapterObject) {
  //     setSelectedChapterId(selectedChapterObject.levelId);
  //   }
  // };

  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();
    setCourese(courseDetails?.data || []);
    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourese(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);
      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
        courseId: data?.courseId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const fetchChapterData = async (courseId, subjectId) => {
    try {
      const chapterDetails = await getAllChapterDetails(courseId, subjectId);
      const chapterCode = chapterDetails?.data?.map((data) => ({
        chapterName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setChapter(chapterCode);
    } catch (error) {
      console.error('Error fetching chapter details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse && selectedSubject) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      if (selectedCourseObject && selectedSubjectObject) {
        fetchChapterData(selectedCourseObject.courseId, selectedSubjectObject.levelId);
      }
    }
  }, [selectedCourse, selectedSubject, Courses]);

  const handleAddRow = () => {
    const newFilterId = filterEntries.length > 0 ? filterEntries[filterEntries.length - 1].id + 1 : 1;
    setFilterEntries((prevEntries) => [
      ...prevEntries,
      {
        id: newFilterId,
        course: '',
        subject: '',
        chapter: '',
      },
    ]);
    setSelectedCourse((prevCourses) => ({
      ...prevCourses,
      [newFilterId]: '',
    }));
    setSelectedSubject((prevSubjects) => ({
      ...prevSubjects,
      [newFilterId]: '',
    }));
    setSelectedChapter((prevChapters) => ({
      ...prevChapters,
      [newFilterId]: '',
    }));
  };

  // Update onSelectCourse function to set selected course for the corresponding filter entry
  const onSelectCourse = (event, id) => {
    let courseId = event.target.value;
    const selectedCourseObject = Courses.find((course) => course.courseName === event.target.value);
    setSelectedCourse((prevCourses) => ({
      ...prevCourses,
      [id]: courseId,
    }));
    if (selectedCourseObject) {
      // setSelectedCourseId(selectedCourseObject.courseId);
      courseId = selectedCourseObject.courseId;
      setSelectedCourseId(courseId);

      console.log(courseId);
      fetchSubjectData(courseId);
    }
  };

  // Update onSelectSubject function to set selected subject for the corresponding filter entry
  const onSelectsubject = (event, id) => {
    const subjectId = event.target.value;
    const selectedSubjectObject = subject.find((subjects) => subjects.subjectName === event.target.value);

    setSelectedSubject((prevSubjects) => ({
      ...prevSubjects,
      [id]: subjectId,
    }));
    if (selectedSubjectObject) {
      // setSelectedCourseId(selectedCourseObject.courseId);
      const subjectId = selectedSubjectObject.levelId;
      const courseId = selectedSubjectObject.courseId;
      setSelectedSubjectId(subjectId);

      console.log(subjectId);
      console.log(selectedSubjectObject);

      fetchChapterData(courseId, subjectId);
    }
  };

  // Update onSelectChapter function to set selected chapter for the corresponding filter entry
  const onSelectChapter = (event, id) => {
    let chapterId = event.target.value;
    const selectedChapterObject = chapter.find((chapters) => chapters.chapterName === event.target.value);

    console.log(event);
    setSelectedChapter((prevChapters) => ({
      ...prevChapters,
      [id]: chapterId,
    }));
    if (selectedChapterObject) {
      // setSelectedCourseId(selectedCourseObject.courseId);
      chapterId = selectedChapterObject.levelId;
      console.log(chapterId);
      setSelectedChapterId(chapterId);
    }
  };

  const handleRemoveRow = (id) => {
    setFilterEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));
  };

  const handleGetUserData = () => {
    // if (selectedCourse && selectedSubject && selectedChapter) {
    //   const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
    //   const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
    //   const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === selectedChapter);

    //   if (selectedCourseObject && selectedSubjectObject && selectedChapterObject) {
    //     const courseId = selectedCourseObject.courseId;
    //     const subjectId = selectedSubjectObject.levelId;
    //     const chapterId = selectedChapterObject.levelId;

    // Create the requestData array with the single object
    const requestData = filterEntries.map((entry) => ({
      index: entry.id,
      courseId: selectedCourseId,
      subjectId: selectedSubjectId,
      chapterId: [selectedChapterId],
    }));

    console.log('Request Data:', requestData);

    // Use Promise.all() to handle multiple requests
    getStudentReportCard(requestData)
      .then((response) => {
        console.log(response);
        setUserReportCard(response.data.data);

        setIsCardVisible(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
    // }
    // }
  };
  console.log(userReportCard, 'userReportList');

  const specificColumns = Array.isArray(userReportCard)
    ? userReportCard.map((item) => {
        return {
          'student Name': (
            <div>
              <Link
                component={RouterLink}
                to={`/dashboard/StudentReport?userId=${item.userId}&selectedCourseId=${encodeURIComponent(
                  selectedCourseId
                )}&selectedSubjectId=${encodeURIComponent(selectedSubjectId)}&selectedChapterId=${encodeURIComponent(
                  selectedChapterId
                )}&name=${encodeURIComponent(item.name)}`} // Add the name parameter here
              >
                {' '}
                {item.name}
              </Link>
            </div>
          ),
          'Mobile No': item?.phoneNumber,

          Action: (
            <div>
              <Link component={RouterLink} to={`/dashboard/StudentReport?userId=${item.userId}`}>
                View Report
              </Link>
            </div>
          ),
        };
      })
    : [];

  return (
    <>
      {isLoading && <Loader />}

      {/* {showLoader && <Loader />} */}
      <ToastContainer />
      <Helmet>
        <title> Student List </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admin Platform - Students List
          </Typography>
        </Stack>
        <Button variant="contained" onClick={handleAddRow} style={{ marginRight: '10px' }}>
          Add Filter
        </Button>
        <br />
        <div>
          {filterEntries.map((entry, index) => (
            <div key={entry.id} className="main" style={{ display: 'flex' }}>
              <div className="col-md-3">
                <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                  <InputLabel id="demo-multiple-name-label">Courses</InputLabel>
                  <Select
                    labelId={`course-label-${entry.id}`}
                    id={`course-${entry.id}`}
                    value={selectedCourse[entry.id] || ''}
                    onChange={(e) => onSelectCourse(e, entry.id)}
                    input={<OutlinedInput label="Vehicle Model" />}
                    startAdornment={
                      <InputAdornment position="start">
                        {isLoading === true ? <CircularProgress size={15} /> : ''}
                      </InputAdornment>
                    }
                    MenuProps={MenuProps}
                  >
                    {Courses.map((course) => (
                      <MenuItem key={course.courseId} value={course.courseName}>
                        {course.courseName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <br />
              <div className="col-md-3" style={{ marginLeft: '10px' }}>
                <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                  <InputLabel id="demo-multiple-name-label">Subject</InputLabel>
                  <Select
                    labelId={`subject-label-${entry.id}`}
                    id={`subject-${entry.id}`}
                    value={selectedSubject[entry.id] || ''}
                    onChange={(e) => onSelectsubject(e, entry.id)}
                    input={<OutlinedInput label="Vehicle Model" />}
                    startAdornment={
                      <InputAdornment position="start">
                        {isLoading === true ? <CircularProgress size={15} /> : ''}
                      </InputAdornment>
                    }
                    MenuProps={MenuProps}
                  >
                    {subject.map((subject) => (
                      <MenuItem key={subject.levelId} value={subject.subjectName}>
                        {subject.subjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-3" style={{ marginLeft: '10px' }}>
                <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                  <InputLabel id="demo-multiple-name-label">Chapter</InputLabel>
                  <Select
                    labelId={`chapter-label-${entry.id}`}
                    id={`chapter-${entry.id}`}
                    value={selectedChapter[entry.id] || ''}
                    onChange={(e) => onSelectChapter(e, entry.id)}
                    input={<OutlinedInput label="Vehicle Model" />}
                    startAdornment={
                      <InputAdornment position="start">
                        {isLoading === true ? <CircularProgress size={15} /> : ''}
                      </InputAdornment>
                    }
                    MenuProps={MenuProps}
                  >
                    {chapter.map((chapter) => (
                      <MenuItem key={chapter.levelId} value={chapter.chapterName}>
                        {chapter.chapterName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Button variant="contained" onClick={() => handleRemoveRow(entry.id)} style={{ marginLeft: '10px' }}>
                Remove
              </Button>
            </div>
          ))}

          <Button
            type="submit"
            variant="contained"
            sx={{ width: '120px', height: '40px', marginTop: '10px', marginLeft: '900px' }}
            onClick={handleGetUserData}
          >
            Search
          </Button>
        </div>
        <br />
        {isCardVisible && <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} />}

        {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <Button variant="contained" color="primary" onClick={handlePrevPage}>
            Previous
          </Button>
          <Button variant="contained" color="primary" onClick={handleNextPage}>
            Next
          </Button>
        </div> */}
        <br />
      </Container>
    </>
  );
}
