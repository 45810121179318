// component

import { FaBookOpen, FaCalendarAlt, FaRegListAlt } from 'react-icons/fa';
import { FiUserPlus } from 'react-icons/fi';
import { FcKindle } from 'react-icons/fc';
import { GoBellFill } from 'react-icons/go';
import { BsFillClipboard2Fill } from 'react-icons/bs';
import { GiArchiveRegister } from 'react-icons/gi';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const userRole = localStorage.getItem('role');

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'AssignRole',
    path: '/dashboard/AssignRole',
    icon: <FiUserPlus />,
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Courses',
    path: '/dashboard/Courses',
    icon: <FaBookOpen />,
    // Show for Publisher and Marketing roles
    visible: userRole === 'PUBLISHER,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Reports',
    path: '/dashboard/products',
    icon: <FaRegListAlt />,
    // Show for Reports user
    visible: userRole === 'REPORT_USER,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Schedular',
    path: '/dashboard/shedular',
    icon: <FaCalendarAlt />,
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Notification',
    path: '/dashboard/Notification',
    icon: <GoBellFill />,
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Manage Tests',
    path: '/dashboard/ManageTest',
    icon: <FcKindle />,
    // Show for Question Author
    visible: userRole === 'QUESTION_AUTHOR,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Manage UAC',
    path: '/dashboard/ManageUAC',
    icon: <BsFillClipboard2Fill />,
    // Show for Marketing role
    visible: userRole === 'STUDENT,MARKETING' || userRole === 'ADMIN',
  },
  {
    title: 'Manage Demo UAC',
    path: '/dashboard/ManageDemoUAC',
    icon: <BsFillClipboard2Fill />,
    // Show for Marketing role
    visible: userRole === 'STUDENT,MARKETING' || userRole === 'ADMIN',
  },
  {
    title: 'TeachersEdge UAC',
    path: '/dashboard/TeachersEdgeUAC',
    icon: <BsFillClipboard2Fill />,
    // Show for Marketing role
    visible: userRole === 'STUDENT,MARKETING' || userRole === 'ADMIN',
  },
  // {
  //   title: 'StudentUAC',
  //   path: '/dashboard/StudentUAC',
  //   icon: <FcKindle />,
  //   // Show for Marketing role
  //   visible: userRole === 'STUDENT,MARKETING' || userRole === 'ADMIN',
  // },
  // {
  //   title: 'Client Register',
  //   path: '/dashboard/ClientRegister',
  //   icon: <GiArchiveRegister />,
  //   // Show for all users
  //   visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  // },

  {
    title: 'Quiz Dashboard',
    path: '/dashboard/QuizDashboard',
    icon: <GiArchiveRegister />,
    // Show for all users
    visible: userRole === 'QUIZ_ADMIN' || userRole === 'QUIZ_ADMIN',
  },
  {
    title: 'Quiz History',
    path: '/dashboard/ExpiredQuiz',
    icon: <GiArchiveRegister />,
    // Show for all users
    visible: userRole === 'QUIZ_ADMIN' || userRole === 'QUIZ_ADMIN',
  },
  // {
  //   title: 'Assignment Result',
  //   path: '/dashboard/AssignmentResult',
  //   icon: <GiArchiveRegister />,
  //   // Show for all users
  //   visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  // },
  {
    title: 'Student List',
    path: '/dashboard/StudentList',
    icon: <GiArchiveRegister />,
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Student Lecture Report',
    path: '/dashboard/StudentLectureReport',
    icon: <GiArchiveRegister />,
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Invoice List',
    path: '/dashboard/InvoiceList',
    icon: <GiArchiveRegister />,
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
  {
    title: 'Generate Content QR',
    path: '/dashboard/GenerateQRContent',
    icon: <GiArchiveRegister />,
    // Show for all users
    visible: userRole === 'ADMIN,STUDENT' || userRole === 'ADMIN',
  },
].filter((item) => item.visible);

export default navConfig;
