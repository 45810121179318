import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Container, Stack, Typography } from '@mui/material';
import '../Styles/product.css';
import { FcDownload } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { FaClipboardList, FaEdit } from 'react-icons/fa';
import { getAllCourses } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';

export default function Courses() {
  const headers = ['Board Name', 'Course', 'Display Name', 'Status', 'Action'];
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);

  // ConditioConnally render the product list based on the form submission
  const handleEditEdit = (courseId) => {
    navigate(`/dashboard/CreateCourse?courseId=${courseId}`);
  };

  const handleStructure = (courseId) => {
   
    navigate(`/dashboard/CourseStructure?courseId=${courseId}`);
  };
  const getCoursesList = () => {
    getAllCourses()
      .then((res) => {
        setCourseData(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCoursesList();
  }, []);

  const specificColumns = courseData.map((item) => {
    return {
      'Board Name': item.boardName,
      Course: item.course,
      'Display Name': item.displayName,
      Status: item.status ? (
        <Button variant="contained" color="primary">
          Active
        </Button>
      ) : (
        <Button variant="contained" color="error">
          Inactive
        </Button>
      ),
      Action: (
        <div>
          <FaEdit
            style={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={() => handleEditEdit(item.courseId)} // Pass the user ID to the edit handler
          />
          <FaClipboardList
            style={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={() => handleStructure(item.courseId)}
          />
        </div>
      ),
    };
  });

  const onCreate = () => {
    navigate('/dashboard/CreateCourse');
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Courses </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Courses
          </Typography>
          <Button variant="contained" onClick={onCreate}>
            Create Course
          </Button>
        </Stack>

        {/* Render the table component */}
        <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
      </Container>
    </>
  );
}
