import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, Button, TextField, FormControlLabel, Switch, } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createClient } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';


export default function CreateClient() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    client_name: '',
    client_number: '',
    school_name: '',
    school_address: '',
    school_email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddClient = (e) => {
    e.preventDefault();
    setIsLoading(true);
    createClient(formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Client Added!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
          navigate(-1);
        } else {
          toast.error("Error occured while creating client", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error occured!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Dashboard: Client Register </title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Add New Client
        </Typography>
        <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: '750px', margin: 'auto' }}>
          <CardContent>
            <form style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <TextField name="client_name" label="Client Name"
                variant="outlined" required
                value={formData.client_name} onChange={handleChange}
              />

              <TextField name="client_number" label="Client Number"
                variant="outlined" required
                value={formData.client_number} onChange={handleChange}
              />
              <TextField name="school_name" label="School Name"
                variant="outlined"
                value={formData.school_name} onChange={handleChange}
              />
              <TextField name="school_address" label="School Address"
                variant="outlined"
                value={formData.school_address} onChange={handleChange}
              />

              <TextField name="school_email" label="School Email"
                variant="outlined"
                value={formData.school_email} onChange={handleChange}
              />

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                <Button type="submit" variant="contained" style={{ width: '150px', alignSelf: 'center', marginLeft: '1rem' }}
                  onClick={handleAddClient}
                >
                  Submit
                </Button>

                <Button type="button" variant="contained"
                  sx={{ width: '120px', textAlign: 'center', marginLeft: '2rem', backgroundColor: 'white', color: 'black', }}
                  onClick={() => { navigate(-1); }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
