import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  alertTitleClasses,
  Dialog,
  AppBar,
  Toolbar,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Styles/play.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FaArrowLeft } from 'react-icons/fa';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';
// import countdownSound from '../../public/assets/images/

import {
  initiateQuizStatus,
  startQuizStatus,
  getQuizsToPlay,
  quizStatusComplete,
  quizStatusNext,
  updatejoinStatus,
  getLeaderBoardData,
  getJoinedUser,
  getAttemptedUserList,
} from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';
import Iconify from '../components/iconify';

export default function PlayQuizPage() {
  const [open, setOpen] = React.useState(false);
  const [joinedUser, setJoinedUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [leaderboardDialogOpen, setLeaderboardDialogOpen] = useState(false);
  const [leaderdata, setLeaderData] = useState([]);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [countdown, setCountdown] = useState(5);
  const [countdownDialogOpen, setCountdownDialogOpen] = useState(false);
  const [showQuizPlay, setShowQuizPlay] = useState([]);
  const navigate = useNavigate();
  const [nextQuestionId, setNextQuestionId] = useState(null); // State variable to hold the next questionId
  const [isSubmitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [prevLeaderData, setPrevLeaderData] = useState([]); // Add a new state variable for previous leader data
  const [isTestSubmitted, setIsTestSubmitted] = useState(false); // Initialize state variable for test submission status
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const quizQuestionTimeInSeconds = params.get('quizQuestionTimeInSeconds'); // Move this line here
  const [remainingTime, setRemainingTime] = useState(parseInt(quizQuestionTimeInSeconds, 10)); // Use it here
  const [shouldFetchUsers, setShouldFetchUsers] = useState(true);
  const [intervalId, setIntervalId] = useState(null); // Store interval ID
  const [testInitiated, setTestInitiated] = useState(false);

  const quizId = params.get('quizId');
  const attemptedUserCount = Array.isArray(users) ? users.filter((user) => user.attempted).length : 0;

  const quizCode = params.get('quizCode');
  console.log(quizCode, 'quizCode');
  const handleGoBack = () => {
    navigate('/dashboard/QuizDashboard');
  };

  const handleChangeToReport = (quizId) => {
    navigate(`/dashboard/QuizReports?quizId=${quizId}`);
  };

  const handleOpenUserDialog = () => {
    setUserDialogOpen(true);
  };

  const handleCloseUserDialog = () => {
    setUserDialogOpen(false);
  };

  useEffect(() => {
    // Close the popup automatically after 3 seconds
    const timeoutId = setTimeout(() => {
      setShowTimeoutPopup(false);
    }, 3000); // Adjust the duration as needed

    // Cleanup function to clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); // This effect runs only once after the component mounts
  // Function to automatically close the timeout popup after 1 second
  useEffect(() => {
    // Set a timer to hide the popup after 1 second
    const timer = setTimeout(() => {
      setShowTimeoutPopup(false);
    }, 3000);

    // Clear the timer when the component unmounts or when showTimeoutPopup changes
    return () => clearTimeout(timer);
  }, [showTimeoutPopup]);

  const handleCloseTimeoutPopup = () => {
    setShowTimeoutPopup(false);
  };

  const defaultImages = [
    '/favicon/assasin.png',
    '/favicon/child.png',
    '/favicon/gladiator.png',
    '/favicon/viking.png',

    // Add more default image paths as needed
  ];

  const startQuestionTimer = () => {
    const currentQuestion = showQuizPlay.data.questionsData[currentQuestionIndex];
    if (!currentQuestion) {
      // Handle the case where there is no current question
      return;
    }

    const quizQuestionTimeInSeconds = currentQuestion.questionTime;
    setRemainingTime(parseInt(quizQuestionTimeInSeconds, 10)); // Reset the timer to the initial value

    const questionTimer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(questionTimer);
          setShowTimeoutPopup(true); // Show timeout popup when time is up
          return 0; // Ensure the timer does not go into negative values
        }
        return prevTime - 1; // Decrement timer by 1 second
      });
    }, 1000);
  };

  const startCountdown = () => {
    setCountdown(5); // Reset countdown to the initial value
    setCountdownDialogOpen(true); // Open the countdown dialog

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(countdownInterval); // Clear the interval when countdown reaches zero
          setCountdownDialogOpen(false); // Close the countdown dialog
          startQuestionTimer(); // Start the question timer
          return 0;
        }
        return prevCountdown - 1; // Decrement countdown by 1 second
      });
    }, 1000);
  };

  const handleCloseSubmitDialog = () => {
    // Close the submit dialog
    setSubmitDialogOpen(false);
  };

  const handleShowLeaderBoard = () => {
    // Open the leaderboard Dialog
    getLeaderBoardData(quizId)
      .then((response) => {
        setLeaderData(response?.data.response);
        console.log(response?.data.response, 'check');
        setPrevLeaderData(leaderdata); // Save previous leader data

        setLeaderboardDialogOpen(true);
      })
      .catch((error) => {
        console.error('Update leader error:', error);
      });
  };
  // console.log(leaderdata, 'checkssss');

  // ... (rest of the code)
  const fetchJoinedUser = () => {
    setIsLoading(true);
    getJoinedUser(quizId)
      .then((response) => {
        setJoinedUser(response.data);
        setIsLoading(false);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  const handleClickInitiate = () => {
    initiateQuizStatus(quizId, 'INITIATED')
      .then((res) => {
        toast.success('Quiz Initiated', {
          position: toast.POSITION.TOP_RIGHT,
        });

        setPollingInterval(setInterval(fetchJoinedUser, 5000)); // Poll every 5 seconds

        setTimeout(() => {
          stopPolling();
        }, 60000);
      })
      .catch((error) => {
        console.error('Update Initiate:', error);
      });
  };

  useEffect(() => {
    if (testInitiated) {
      const intervalId = setInterval(fetchJoinedUser, 5000); // Poll every 5 seconds
      setPollingInterval(intervalId);
    }

    return () => {
      clearInterval(pollingInterval);
    };
  }, [testInitiated]);

  const stopPolling = () => {
    clearInterval(pollingInterval);
    setPollingInterval(null);
  };

  useEffect(() => {
    fetchJoinedUser();

    return () => {
      stopPolling();
    };
  }, [quizId]);
  // console.log(joinedUser, 'checker');

  const handleCloseLeaderboardDialog = () => {
    // Close the leaderboard Dialog
    setLeaderboardDialogOpen(false);
  };

  const handleClickOpen = () => {
    startCountdown();
    setTestInitiated(false);
    stopPolling(); // Stop polling when the quiz starts

    const currentQuestion = showQuizPlay?.data?.questionsData[currentQuestionIndex];
    if (!currentQuestion) {
      // Handle the case where there is no current question
      return;
    }

    const questionId = currentQuestion.questionId;
    const currentSequence = currentQuestion.sequence;

    setOpen(true);
    // Update every second
    startQuizStatus(quizId, 'STARTED', questionId, currentSequence)
      .then((res) => {
        console.log(res?.data, res?.data?.message);
        if (res.status === 200) {
          toast.success(res?.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOpen(true);
          setTestInitiated(false);

          console.log('quizInMe', res?.data);
        } else {
          setOpen(false);
          toast.error(res?.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {});
    // Pass the questionId to fetchUserList
    fetchUserList(questionId);
    const intervalId = setInterval(() => {
      fetchUserList(questionId); // Pass the current questionId
    }, 2000); // Refresh every 2 seconds

    // Save the intervalId in state
    setIntervalId(intervalId);
  };

  const handleQuizSubmit = () => {
    setOpen(true);
    setSubmitDialogOpen(true);
    // Clear the interval for fetching user list
    clearInterval(intervalId);
    quizStatusComplete(quizId, 'COMPLETED')
      .then((res) => {
        console.log(res?.data, res?.data?.message);
        if (res.status === 200) {
          toast.success(res?.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOpen(true);
          setIsTestSubmitted(true); // Update test submission status to true

          console.log('quizInMe', res?.data);
        } else {
          setOpen(false);
          toast.error(res?.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {});
  };

  const handleNotifi = () => {
    updatejoinStatus(quizId, 'NOT_STARTED')
      .then((res) => {
        console.log(res?.data, res?.data?.message);
        toast.success('Notification Send', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error('Error while sending Notification:', error);
      });
  };

  const handlesubmits = () => {
    setIsLoading(true);
    getQuizsToPlay(quizId)
      .then((response) => {
        setShowQuizPlay(response?.data);
        console.log('data', response?.data);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Update leader error:', error);
      });
  };
  // console.log(showQuizPlay);

  useEffect(() => {
    handlesubmits();
  }, []);

  const startCountsdown = () => {
    setCountdownDialogOpen(true);
    // playCountdownSound(); // Play the countdown sound effect

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Set a timeout to close the countdown dialog after the countdown finishes
    setTimeout(() => {
      setCountdownDialogOpen(false);
      clearInterval(countdownInterval); // Clear the interval
    }, 5000);
  };

  const fetchNextQuestions = () => {
    // Clear the interval for fetching user list
    clearInterval(intervalId);
    console.log(currentQuizIndex, showQuizPlay.data?.questionsData.length, 'check index:)');
    if (showQuizPlay.data?.questionsData.length > currentQuizIndex + 1) {
      setCurrentQuestionIndex(currentQuizIndex + 1);
      startCountdown(); // Start the countdown
    } else {
      alert('Question over');
    }

    const nextQuestionIndex = currentQuestionIndex + 1;
    // Ensure that there is a next question available
    if (nextQuestionIndex < showQuizPlay.data?.questionsData.length) {
      const nextQuestionId = showQuizPlay.data?.questionsData[nextQuestionIndex].questionId;

      const currentSequence = showQuizPlay.data?.questionsData[nextQuestionIndex].sequence;

      quizStatusNext(quizId, nextQuestionId, 'STARTED', currentSequence)
        .then((response) => {
          console.log('Response body:', response.data.response);

          if (response.status === 200) {
            return response; // assuming the response is in JSON format
          }
          console.error('Failed to fetch next questions. Response:', response);
          return 'Failed to fetch next questions';
        })
        .then((data) => {
          if (data) {
            console.log('Data:', data);

            // Extract the next question from the array
            const nextQuestion = data.questionsData;

            // Update the state with the new question
            setShowQuizPlay((prevData) => ({
              ...prevData,
              questionsData: [...prevData.data.questionsData, nextQuestion],
            }));

            setCurrentQuestionIndex(nextQuestionIndex);

            // Pass the next questionId to fetchUserList            // Pass the questionId to fetchUserList
            fetchUserList(nextQuestionId);
            const intervalId = setInterval(() => {
              fetchUserList(nextQuestionId); // Pass the current questionId
            }, 2000); // Refresh every 2 seconds

            // Save the intervalId in state
            setIntervalId(intervalId);
          }
        })
        .catch((error) => {
          console.error('Error fetching next questions:', error);
        });
    } else {
      console.log('No more questions available.');
    }
  };

  const fetchUserList = (questionId) => {
    setIsLoading(true);
    getAttemptedUserList(quizId, questionId)
      .then((response) => {
        setUsers(response.data);
        setIsLoading(false);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  // Inside the component
  useEffect(() => {
    let intervalId;

    // Define a function to start the interval
    const startInterval = (nextQuestionId) => {
      intervalId = setInterval(() => {
        if (nextQuestionId) {
          fetchUserList(nextQuestionId);
        }
      }, 1000); // Refresh every second
    };

    // Start the interval only if the condition is met
    if (shouldFetchUsers && nextQuestionId) {
      startInterval(nextQuestionId);
    }

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };
  }, [shouldFetchUsers, nextQuestionId]);

  const handleClose = () => {
    setOpen(false);
  };

  function generateRandomColor() {
    const letters = '0123456789ABCDEF';

    // Use Array.from to create an array with 6 elements
    const colorArray = Array.from({ length: 6 }, () => {
      const randomIndex = Math.floor(Math.random() * 16);
      return letters[randomIndex];
    });

    // Join the array elements to form the color string
    return `#${colorArray.join('')}`;
  }

  // Function to get the CSS class for rank change animation
  const getRankChangeClass = (currentRank, index) => {
    const prevRank = prevLeaderData[index]?.rank || 0; // Get previous rank if available
    if (currentRank < prevRank) {
      return 'rank-down'; // CSS class for rank decrease
    }
    if (currentRank > prevRank) {
      return 'rank-up'; // CSS class for rank increase
    }
    return ''; // No change in rank
  };

  return (
    <>
      {isLoading && <Loader />}

      <ToastContainer />
      <Helmet>
        <title> PlayQuiz </title>
      </Helmet>
      <Container>
        <FaArrowLeft
          style={{
            cursor: 'pointer',
            gap: '2rem',
            height: '40px',
            width: '40px',
            backgroundColor: '#86A8CF',
            borderRadius: '60%',
          }}
          onClick={handleGoBack} // Pass the user ID to the delete handler
        />
        <br />
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h2"
            gutterBottom
            sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', margin: '2px' }}
          >
            <img src="\favicon\download.jpg" alt="png" height={65} />
            Manage Quiz
          </Typography>
          <div className="Button-Quiz" style={{ display: 'flex', gap: '1rem' }}>
            1.{' '}
            <Button variant="contained" onClick={handleNotifi}>
              Send User Notification
            </Button>
            2.{' '}
            <Button variant="contained" onClick={handleClickInitiate}>
              Initiate Quiz
            </Button>
            3.{' '}
            <Button variant="contained" onClick={handleClickOpen}>
              start Quiz
            </Button>
          </div>
        </Stack>
        <br />
        <div className="board">
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Total Joined Users: {joinedUser.length}</h4>
              <div style={{ marginTop: '10px' }}>
                <h4>Quiz Code: {quizCode}</h4>
              </div>
            </div>
          </div>

          {joinedUser.map((value, index) => (
            <div className="item-container" style={{ borderRadius: '15px', overflow: 'hidden', width: '300px' }}>
              <div
                className="item"
                style={{
                  display: 'flex',
                  gap: '2rem',
                  backgroundColor: generateRandomColor(),
                  padding: '10px',
                  marginBottom: '10px', // Adjust the margin bottom as needed
                }}
              >
                <img
                  src={value.img || defaultImages[index % defaultImages.length]}
                  height={'50'}
                  width={'50px'}
                  alt=""
                />
                <div className="info" style={{ width: `${value.username.length * 10}px` }}>
                  <h3 className="name text-dark">{value.username}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Dialog className="quizpage" fullScreen open={open} onClose={handleClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Button edge="start" color="inherit" onClick={handleClose} aria-label="close">
                close
              </Button>
              <div className="timer-container">
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{
                    backgroundColor: 'white',
                    marginLeft: '1000px',
                    position: 'fixed',
                    borderRadius: '2px',
                    width: '270px',
                    padding: '-2px',
                  }}
                >
                  Time remaining: {remainingTime} seconds
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          <div className="quizLable">
            <h1>
              <img src="\favicon\quizicon.png" alt="quiz" height={70} />
              Play Quiz - - {quizCode}
            </h1>

            <div className="questions">
              {showQuizPlay && showQuizPlay.data?.questionsData ? (
                showQuizPlay.data?.questionsData.map((question, index) => {
                  if (index === currentQuizIndex) {
                    return (
                      <div
                        key={showQuizPlay.data?.questionsData[currentQuestionIndex].questionId}
                        className="question-container"
                      >
                        <div className="lifeline-container" style={{ marginLeft: '1200px' }}>
                          <Button
                            variant="contained"
                            startIcon={<Iconify icon="mdi:progress-tick" />}
                            onClick={handleOpenUserDialog}
                          >
                            <span className="mdi mdi-lightbulb-on-outline mdi-24px lifeline-icon">
                              {users?.questionAttemptedCount}
                            </span>
                          </Button>
                        </div>

                        <div className="lifeline-container" style={{ display: 'flex', gap: '1rem' }}>
                          <p>
                            <span>
                              {currentQuestionIndex + 1} of {showQuizPlay.data?.questionsData.length}
                            </span>
                          </p>
                        </div>
                        <h5>
                          {showQuizPlay.data?.questionsData[currentQuestionIndex].title.map((part, i) => {
                            if (part.type === 'text') {
                              return (
                                <span
                                  key={i}
                                  style={{
                                    fontFamily: part.font, // Apply the font style dynamically
                                    fontSize: '20px',
                                  }}
                                >
                                  {part.content}
                                </span>
                              );
                            }
                            if (part.type === 'latex') {
                              return <BlockMath key={i}>{part.content}</BlockMath>;
                            }
                            if (part.type === 'image') {
                              return <img key={i} src={part.content} alt={`Question ${index} Img`} />;
                            }
                            return null;
                          })}
                        </h5>

                        {showQuizPlay.data?.questionsData[currentQuestionIndex].answers.map((answer) => (
                          <div
                            key={answer.answerId}
                            className={`options ${answer.correctAnswer ? 'correct-answer' : ''}`}
                          >
                            {answer.title.map((part, i) => {
                              // Check if the answer is correct
                              const isCorrect = answer.correctAnswer;
                              // Define CSS classes based on whether the answer is correct
                              const optionClasses = `option ${isCorrect ? 'correct-answer' : ''}`;
                              if (part.type === 'text') {
                                return (
                                  <span
                                    key={i}
                                    className={optionClasses} // Apply the conditional CSS classes
                                    style={{
                                      fontFamily: part.font, // Apply the font style dynamically
                                      fontSize: '15px',
                                      backgroundColor: isCorrect ? 'green' : 'transparent', // Apply green background if the answer is correct
                                      padding: '5px', // Add padding for better appearance
                                    }}
                                  >
                                    {part.content}
                                  </span>
                                );
                              }
                              if (part.type === 'latex') {
                                return <BlockMath key={i}>{part.content}</BlockMath>;
                              }
                              if (part.type === 'image') {
                                return <img key={i} src={part.content} alt={`Answer ${answer.answerId} Img`} />;
                              }
                              return null;
                            })}
                          </div>
                        ))}
                      </div>
                    );
                  }
                  return false;
                })
              ) : (
                <p>Loading questions...</p>
              )}
            </div>
          </div>
          <div className="button-container">
            <Button variant="contained" onClick={fetchNextQuestions}>
              Next
            </Button>

            <Button variant="contained" onClick={handleShowLeaderBoard}>
              LeaderBoard
            </Button>
            <Button variant="contained" color="success" onClick={handleQuizSubmit}>
              Submit Test
            </Button>
            {isTestSubmitted && ( // Conditionally render the "Report" button when the test is submitted
              <Button variant="contained" onClick={() => handleChangeToReport(quizId)}>
                Reports
              </Button>
            )}
          </div>
        </Dialog>
        <Dialog fullWidth={fullWidth} open={leaderboardDialogOpen} onClose={handleCloseLeaderboardDialog}>
          {' '}
          <DialogTitle sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'darkorchid' }}>
            LeaderBoard
          </DialogTitle>
          <br />
          <DialogContent>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: '400px',
              }}
            >
              <div className="board">
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <h4>Total Joined Users: {joinedUser.length}</h4>
                </div>
                {leaderdata.map((value, index) => (
                  <div
                    className={`flex ${getRankChangeClass(value.rank, index)}`} // Apply CSS class for rank change
                    key={index}
                    style={{
                      backgroundColor: value.isAnsTrue ? 'lightgreen' : 'lightpink',
                      borderRadius: '15px',
                      width: '600px',
                    }}
                  >
                    <div
                      className="item"
                      style={{ display: 'flex', gap: '2rem', padding: '10px', marginBottom: '10px', width: '700px' }}
                    >
                      <h4 className="serial-number">{index + 1}.</h4> {/* Serial number */}
                      <img
                        src={value.profilePic || defaultImages[index % defaultImages.length]}
                        height={'70px'}
                        width={'50px'}
                        alt=""
                      />
                      <div className="info">
                        <h3 className="name text-dark">{value.userName}</h3>
                        <span>Rank{value.rank}</span>
                      </div>
                      {/* <div className="item" style={{ marginLeft: '90px', justifyContent: 'center' }}>
                        <h4>{value.answerTime} sec</h4>
                      </div> */}
                      <div className="item" style={{ marginLeft: '90px', justifyContent: 'center' }}>
                        <h4>Points {value.pointAssigned}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog fullWidth={fullWidth} open={countdownDialogOpen} onClose={() => setCountdownDialogOpen(false)}>
          <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
            Get ready for the next question!
          </DialogTitle>
          <DialogContent>
            <Typography variant="h1" align="center">
              {countdown}
            </Typography>
          </DialogContent>
        </Dialog>
        {/* Submit Dialog */}
        <Dialog open={isSubmitDialogOpen} onClose={handleCloseSubmitDialog}>
          <DialogTitle>Test Submitted</DialogTitle>
          <DialogContent>
            <p>Your test has been successfully submitted.</p>
            {/* You can customize the content of the dialog as needed */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubmitDialog} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* Timeout Popup */}
        <Dialog open={showTimeoutPopup} onClose={() => setShowTimeoutPopup(false)}>
          <DialogTitle>Timeout</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The time for this question has expired. Please proceed to the next question.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTimeoutPopup(false)} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {/* Define the dialog */}
        <Dialog open={userDialogOpen} onClose={handleCloseUserDialog}>
          <DialogTitle>User's Attempt Status</DialogTitle>
          <DialogContent>
            <div>
              <h4>Attempted Users:</h4>
              <ul>
                {Array.isArray(users?.questionAttemptUserResponses) &&
                  users.questionAttemptUserResponses
                    .filter((user) => user.isQuestionAttempt)
                    .map((user, index) => <li key={index}>{user.username}</li>)}
              </ul>
              <h4>Unattempted Users:</h4>
              <ul>
                {Array.isArray(users?.questionAttemptUserResponses) &&
                  users.questionAttemptUserResponses
                    .filter((user) => !user.isQuestionAttempt)
                    .map((user, index) => <li key={index}>{user.username}</li>)}
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUserDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
