import { Helmet } from 'react-helmet-async';

import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  alertTitleClasses,
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
// import JSZip from 'jszip';
// import XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { FaEdit, FaClipboardList, FaTrash } from 'react-icons/fa';
import { IconName } from 'react-icons/ai';

import { getExpireQuiz } from '../Servies/AdmiServices';

import Iconify from '../components/iconify';

import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function ExpiredQuiz() {
  const navigate = useNavigate();
  const school = localStorage.getItem('schoolName');
  console.log(school);
  const [search, setSearch] = useState('');

  const [student, setStudents] = useState([]);
  const headers = ['Quiz Name', 'Schedule Date', 'Quiz Code', 'Status', 'Action'];
  const [showTable, setShowTable] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [downloadData, setDownloadData] = useState();

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState('');
  const [div, setDiv] = useState();

  const handleQuizHistory = () => {
    setIsLoading(true);
    getExpireQuiz()
      .then((res) => {
        setShowTable(res.data.response);
        console.log('check delete', res.data.response);
        toast.success(res?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching course data:', error);
      });
  };

  console.log(showTable);
  useEffect(() => {
    handleQuizHistory();
  }, []);

  const handleEditEdit = (quizId) => {
    navigate(`/dashboard/QuizReports?quizId=${quizId}`);
  };

  const specificColumns = showTable
    ? showTable.map((item) => {
        return {
          'Quiz Name': item?.quizName,
          'Schedule Date': item?.scheduleDateTime,
          'Quiz Code': item.quizCode,
          Status: item.isQuizManual ? (
            <Button variant="contained" color="primary">
              Manual
            </Button>
          ) : (
            <Button variant="contained" color="error">
              Auto
            </Button>
          ),
          Action: (
            <Button
              variant="contained"
              startIcon={<Iconify icon="mdi:approve" />}
              onClick={() => handleEditEdit(item.quizId)} // Pass the user ID to the edit handler
            >
              Report
            </Button>
          ),
        };
      })
    : [];

  return (
    <>
      {isLoading && <Loader />}

      {/* {showLoader && <Loader />} */}
      <ToastContainer />
      <Helmet>
        <title> Quiz History </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Quiz Admin Platform - Quiz History
          </Typography>
        </Stack>

        <br />
        <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <Button variant="contained" color="primary" onClick={handlePrevPage}>
            Previous
          </Button>
          <Button variant="contained" color="primary" onClick={handleNextPage}>
            Next
          </Button>
        </div> */}
        <br />
      </Container>
    </>
  );
}
