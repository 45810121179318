import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import { FaEdit, FaTrash } from 'react-icons/fa';
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  FormControl,
  Select,
  CircularProgress,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { getUserReports } from '../Servies/AdmiServices';

import 'react-toastify/dist/ReactToastify.css';
import TableData from '../components/common/TableData';

import Loader from '../components/common/Loader';

export default function QuizReports() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showTable, setShowTable] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [isLoading, setIsLoading] = useState(false);
  const [editingRow, setEditingRow] = useState(null); // Track the editing row
  const [editedData, setEditedData] = useState({});
  // const [apiRefresh, setApiRefresh] = useState(false);
  const [Courses, setCourse] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [rows, setRows] = useState([]); // Add this line to define the 'rows' state
  const [headers, setHeaders] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState('');
  const params = new URLSearchParams(window.location.search);
  // const headers = ['Rank', 'Username', 'Answer Time', 'Points', 'Accuracy'];

  const quizId = params.get('quizId');
  console.log(quizId);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const newPageSize = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const fetchData = () => {
    getUserReports(quizId)
      .then((response) => {
        const leaderboardData = response.data.response.leaderBoard;
        const headerData = response.data.response.leaderBoardHeader;

        const updatedHeaders = headerData.map((header) => `Q${header.questionNo}`);

        setHeaders(['Rank', 'Username', 'Points', 'Accuracy', ...updatedHeaders]);

        // Check if leaderboardData is defined before setting showTable
        if (leaderboardData) {
          const updatedRows = leaderboardData.map((user) => {
            const rowData = {
              Rank: user.rank,
              Username: user.userName,
              // 'Answer Time': user.answerTime,
              Points: user.pointAssigned,
              Accuracy: user.accuracy,
            };

            updatedHeaders.forEach((header) => {
              const questionNo = parseInt(header.substring(1), 10);
              const questionAccuracy = user.questionAccuracy.find((qa) => qa.questionNo === questionNo);

              const cellValue = questionAccuracy ? (
                questionAccuracy.isAnsTrue ? (
                  <span style={{ color: 'green' }}>&#10004;</span> // Checkmark for correct
                ) : (
                  <span style={{ color: 'red' }}>&#10008;</span>
                ) // Cross mark for incorrect
              ) : (
                'N/A'
              );

              rowData[header] = cellValue;
            });

            return rowData;
          });

          setRows(updatedRows);
        }

        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Dashboard: Quiz Report </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Quiz Report
        </Typography>

        <Card>
          <br />
          {/* Check if headers is defined before using it */}
          {headers && <TableData data={rows} headers={headers} rowsPerPageOptions={[10, 20, 30]} />}
        </Card>
      </Container>
    </>
  );
}
