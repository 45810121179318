import React, { useEffect, useState } from 'react';
import { RouterLink, useNavigate } from 'react-router-dom';
import {
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Box,
  Stack,
  Typography,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { toast } from 'react-toastify';

import { getAllQuizList } from '../Servies/AdmiServices';

import Iconify from '../components/iconify';

export default function QuizDashboard() {
  const [quizList, setQuizList] = useState({});

  const navigate = useNavigate();

  const handleNewUserClick = () => {
    navigate('/dashboard/Activity');
  };

  const handleQuiz = () => {
    getAllQuizList()
      .then((res) => {
        console.log('data', res?.data);

        setQuizList(res?.data?.response);
      })
      .catch((error) => {
        console.error('Update leader error:', error);
      });
  };

  const handleEditEditss = (quizId, quizCode, quizQuestionTimeInSeconds) => {
    // You can perform any additional logic here before navigating
    navigate(
      `/dashboard/PlayQuizPage?quizId=${quizId}&quizCode=${quizCode}&quizQuestionTimeInSeconds=${quizQuestionTimeInSeconds}`
    );
  };
  useEffect(() => {
    handleQuiz();
  }, []);

  // const handleClick = (quizId) => {
  //   updatejoinStatus(quizId, 'NOT_STARTED')
  //     .then((res) => {
  //       console.log(res?.data, res?.data?.message);
  //       if (res.status === 200) {
  //         toast.success(res?.data?.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         setQuizId(res?.data.quizId);
  //         console.log('quizInMe', res?.data);

  //         navigate(`/dashboard/PlayQuizPage?quizId=${quizId}`, { replace: true });
  //       } else {
  //         toast.error(res?.data?.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     })
  //     .catch((err) => {});
  //   // navigate('/dashboard', { replace: true });
  // };

  return (
    <>
      {/* <Helmet>
        <title>Leaderboard</title>
      </Helmet> */}
      <Container>
        <Helmet>
          <title>Quiz Dashboard</title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Quiz Admin Platform - Quiz Dashboard{' '}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              // onClick={
              //   Navigate('/dashboard/app/manageuser')
              //   }>
              onClick={handleNewUserClick}
            >
              Create Content
            </Button>
          </Stack>
          <Container>
            {quizList.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Quiz Name</TableCell>
                    <TableCell>Schedule Date</TableCell>
                    <TableCell>Quiz Code</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {/* <TableBody>
              {leaderdata.length>0  && leaderdata.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link component={RouterLink} to={`/dashboard/manageleader`} color="primary">
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.grade}</TableCell>
                  <TableCell>{row.topicViewed}</TableCell>
                  <TableCell>{row.assessment}</TableCell>
                  <TableCell>{row.growthScore}</TableCell>
                  <TableCell>{row.score}</TableCell>
                </TableRow>
              ))}


              
            </TableBody> */}

                <TableBody>
                  {quizList.length > 0 &&
                    quizList.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.quizName}</TableCell>
                        <TableCell>{row.scheduleDateTime}</TableCell>
                        <TableCell>{row.quizCode}</TableCell>

                        <TableCell>
                          <Button
                            variant="contained"
                            startIcon={<Iconify icon="mdi:approve" />}
                            onClick={() => handleEditEditss(row.quizId, row.quizCode, row.quizQuestionTimeInSeconds)} // Pass the user ID to the edit handler
                          >
                            Join
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              'NO Data Available'
            )}
          </Container>
        </Container>
      </Container>
    </>
  );
}
