import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Stack, Typography } from '@mui/material';
import '../Styles/product.css';
import { FcDownload } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { downloadUac, getAllManageUAC } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';

// import { downloadExcelFile } from '../utils/dwonloadExcelFile';

export default function ManageUAC() {
  const headers = ['School Name', 'UAC Count', 'RequestBy', 'RequestOn', 'Action'];
  const navigate = useNavigate();
  const [UACData, setUACData] = useState([]);

  const handleDownload = async (genId) => {
    try {
      const res = await downloadUac(genId);
      const jsonData = res?.data;

      if (!jsonData) {
        console.error('Response data is empty.');
        return;
      }

      const fileName = 'UAC_Codes.xlsx';
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(jsonData), 'Data');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(excelBuffer)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    } catch (err) {
      console.error('Error downloading file:', err);
    }
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i += 1) {
      view[i] = s.charCodeAt(i) % 256;
    }
    return buf;
  }

  const onMangeUAC = () => {
    getAllManageUAC()
      .then((res) => {
        setUACData(res?.data);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    onMangeUAC();
  }, []);
  const specificColumns = UACData.map((item) => {
    const date = new Date(item.requestedDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return {
      'School Name': item.schoolName,
      'UAC Count': item.uacCount,
      RequestBy: item.userName,
      RequestOn: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
      Action: <FcDownload style={{ cursor: 'pointer' }} onClick={() => handleDownload(item.genId)} />,
    };
  });

  const onCreate = () => {
    navigate('/dashboard/CreateUAC');
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title> Dashboard: Manage UAC </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Manage UAC
          </Typography>
          <Button variant="contained" onClick={onCreate}>
            CreateUAC
          </Button>
        </Stack>

        {/* Render the table component */}
        <TableData data={specificColumns} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
      </Container>
    </>
  );
}
