import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer ,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import Iconify from '../../../components/iconify';

import { login } from '../../../Api/auth';



// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [username, setUserName] = useState();

  // const { setAuthTokens, } = useAuth();
  const [password, setPassword] = useState();

  const handleClick = () => {
    login(username, password)
      // login(username, password)
      .then((res) => {
        console.log(res?.data, res?.data?.message)
        if (res.status === 200) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/dashboard', { replace: true });
        } else {
          toast.error("Invalid user name or password", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {});
  };
  return (
    <>
    <ToastContainer />
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          onChange={(e) => {
            setUserName(e.target.value);
          }}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
