import React, { useState } from 'react';
import '../../../Styles/product.css';
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


function ProductList({ products }) {


  
  const rows = products.map((product, index) => ({
    id: index + 1,
    name: product.name,
     contactNo:product?.contactNo,
           email:product?.email,
           registrationDate:product?.registrationDate,
           courseAssigned:product?.courseAssigned  ,
           courseList:product?.courseList ,
           uacCode:product?.uacCode,
           assignedOn:product?.assignedOn ,
           isFree:product?.isFree  
  
    // Add more properties as needed based on your API response structure
    // For example: name, contactNo, email, registrationDate, etc.
  }));


  const columns : GridColDef[] = [
    { field: 'name', headerName: 'Name',  flex: 1  },
    { field: 'contactNo', headerName: 'Contact No',  flex: 1  },
    { field: 'email', headerName: 'Email',  flex: 1  },
    { field: 'registrationDate', headerName: 'Registration Date',  flex: 1  },
    { field: 'courseAssigned', headerName: 'Course Assigned',  flex: 1  },
    { field: 'courseList', headerName: 'Course List',  flex: 1  },
    { field: 'uacCode', headerName: 'UAC Code',  flex: 1  },
    { field: 'assignedOn', headerName: 'Assigned On',  flex: 1  },
    { field: 'isFree', headerName: 'Status',  flex: 1  },
  ];
  const [activeTab, setActiveTab] = useState('active');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderDataGrid = () => (
    <div style={{ height: 400, width: '1200px' , overflowX: 'auto' }}>
   
   <DataGrid
              rows={rows}
              columns={columns}
              pagination
             
            />
   
  </div>
  );
  return (
    <div className="container">
      <div className="tabs">
        <button
          className={activeTab === 'active' ? 'active-tab' : 'tab'}
          onClick={() => handleTabClick('active')}
        >
          
        Course Details
        </button>
        <button
          className={activeTab === 'user' ? 'active-tab' : 'tab'}
          onClick={() => handleTabClick('user')}
        >
          Activity
        </button>
      </div>
      {/* <div className="table-container">
        {activeTab === 'active' && (
          <div style={{ height: 400, width: '100%', overflowX: 'auto' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
             
            />
          </div>
        )}
        {activeTab === 'user' && (
          <div style={{ height: 400, width: '100%', overflowX: 'auto' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              
            />
          </div>
        )}
      </div> */}
      <div className="table-container">
        {activeTab === 'active' && renderDataGrid()}
        {activeTab === 'user' && renderDataGrid()}
      </div>
    </div>
  );




}

export default ProductList;
