import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, InputAdornment, MenuItem, OutlinedInput, Select, FormControl, InputLabel, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUacForClient, getAllCourseDetails, getAllSubjectDetails } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';


export default function CreateUacForClient() {
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState([
        { selectedCourse: '', selectedSubject: '', selectAll: false, uacCount: 0 }
    ]);
    const [courses, setCourses] = useState([]);
    const [subjects, setSubjects] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const handleChangeCourse = async () => {
        const courseDetails = await getAllCourseDetails();
        const coursesData = courseDetails?.data?.map((data) => ({
            courseName: data?.courseDisplayName,
            courseId: data?.courseId,
        }));
        setCourses(coursesData);
    };

    const fetchSubjectData = async (courseId) => {
        try {
            const subjectDetails = await getAllSubjectDetails(courseId);
            const subjectsData = subjectDetails?.data?.map((data) => ({
                subjectName: data?.levelDisplayName,
                levelId: data?.levelId,
            }));
            setSubjects(prevSubjects => ({
                ...prevSubjects,
                [courseId]: subjectsData
            }));
        } catch (error) {
            console.error('Error fetching subject details:', error);
        }
    };

    useEffect(() => {
        handleChangeCourse();
    }, []);

    useEffect(() => {
        formFields.forEach(field => {
            const selectedCourseObject = courses.find(course => course.courseName === field.selectedCourse);
            if (selectedCourseObject && !subjects[selectedCourseObject.courseId]) {
                fetchSubjectData(selectedCourseObject.courseId);
            }
        });
    }, [formFields, courses]);

    const handleFieldChange = (index, field, value) => {
        const newFormFields = [...formFields];
        newFormFields[index][field] = value;
        setFormFields(newFormFields);
    };

    const addFormField = () => {
        setFormFields([...formFields, { selectedCourse: '', selectedSubject: '', selectAll: false, uacCount: 0 }]);
    };

    const handleCreateUAC = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = [];
        formFields.forEach((field) => {
            const selectedCourseObject = courses.find(course => course.courseName === field.selectedCourse);
            const selectedSubjectObject = subjects[selectedCourseObject.courseId]?.find(subject => subject.subjectName === field.selectedSubject);
            const payload = {
                course_id: selectedCourseObject.courseId,
                max_registration_count: parseInt(field.uacCount, 10),
                client_id: state,
                is_all_subject: field.selectAll,
                ...(field.selectAll ? {} : { subject_id: selectedSubjectObject?.levelId }),
            };
            formData.push(payload)
            console.log(payload);
        });
        console.log(formData)
        createUacForClient(formData).then((respsonse) => {
            setIsLoading(true);
            if (respsonse.status === 200) {
                setIsLoading(true);
                console.log("UAC data added")
                toast.success("UAC data added.")
                setTimeout(() => {
                    navigate('/dashboard/TeachersEdgeUAC');
                }, 1000);
            } else {
                setIsLoading(true);
                toast.error("Couldn't add UAC data.")
            }
        }).catch((error) => {
            setIsLoading(true);
            console.log(error)
            toast.error("Error while adding UAC.")
        })
        setIsLoading(false);
    };

    return (
        <>
            {isLoading && <Loader />}
            <ToastContainer />
            <Helmet>
                <title>Dashboard: Create UAC for Client</title>
            </Helmet>
            <Container>
                <form onSubmit={handleCreateUAC}>
                    {formFields.map((field, index) => (
                        <div className="main" style={{ display: 'flex', marginBottom: '20px' }} key={index}>
                            <FormControl sx={{ m: 1, width: 200 }} size={'small'} required>
                                <InputLabel id={`course-label-${index}`}>Courses</InputLabel>
                                <Select
                                    labelId={`course-label-${index}`}
                                    id={`course-${index}`}
                                    value={field.selectedCourse}
                                    onChange={(e) => handleFieldChange(index, 'selectedCourse', e.target.value)}
                                    input={<OutlinedInput label="Courses" />}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {isLoading === true ? <CircularProgress size={15} /> : ''}
                                        </InputAdornment>
                                    }
                                >
                                    {courses.map((course) => (
                                        <MenuItem key={course.courseId} value={course.courseName}>
                                            {course.courseName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, width: 200 }} size={'small'} disabled={field.selectAll}>
                                <InputLabel id={`subject-label-${index}`}>Subject</InputLabel>
                                <Select
                                    labelId={`subject-label-${index}`}
                                    id={`subject-${index}`}
                                    value={field.selectedSubject}
                                    onChange={(e) => handleFieldChange(index, 'selectedSubject', e.target.value)}
                                    input={<OutlinedInput label="Subject" />}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {isLoading === true ? <CircularProgress size={15} /> : ''}
                                        </InputAdornment>
                                    }
                                >
                                    {(subjects[courses.find(course => course.courseName === field.selectedCourse)?.courseId] || []).map((subject) => (
                                        <MenuItem key={subject.levelId} value={subject.subjectName}>
                                            {subject.subjectName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, width: 200 }} size={'small'}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={field.selectAll}
                                            onChange={(e) => handleFieldChange(index, 'selectAll', e.target.checked)}
                                        />
                                    }
                                    label="All Subject"
                                />
                            </FormControl>

                            <TextField
                                name="max_registration_count"
                                label="UAC Count"
                                type="number"
                                value={field.uacCount}
                                onChange={(e) => handleFieldChange(index, 'uacCount', e.target.value)}
                                sx={{ width: 200, marginBottom: '20px', marginLeft: '20px' }}
                            />

                            <TextField
                                name="client_id"
                                type="number"
                                value={state}
                                disabled
                                sx={{ width: 200, marginBottom: '20px', marginLeft: '20px' }}
                            />
                        </div>
                    ))}

                    <Button type="button" variant="outlined" onClick={addFormField} sx={{ marginBottom: '20px' }}>
                        Add More
                    </Button>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Button type="submit" variant="contained" sx={{ width: '120px', textAlign: 'center' }}>
                            Create
                        </Button>

                        <Button type="button" variant="contained"
                            sx={{ width: '120px', textAlign: 'center', marginLeft: '1rem', backgroundColor: 'white', color: 'black' }}
                            onClick={() => { navigate(-1); }}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </Container>
        </>
    );
}


// Old Script with Working Single UAC Adding feature
// import React, { useEffect, useState } from 'react'
// import { CircularProgress, Container, InputAdornment, MenuItem, OutlinedInput, Select, FormControl, InputLabel, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
// import { toast,ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { createUacForClient, getAllCourseDetails, getAllSubjectDetails } from '../Servies/AdmiServices';
// import Loader from '../components/common/Loader';

// export default function CreateUacForClient() {
//     const [isLoading, setIsLoading] = useState(false);
//     const navigate = useNavigate();
//     const location = useLocation();
//     const state = location.state;

//     const [courses, setCourses] = useState([]);
//     const [subjects, setSubjects] = useState([]);
//     const [selectedCourse, setSelectedCourse] = useState('');
//     const [selectedSubject, setSelectedSubject] = useState('');
//     const [selectAll, setSelectAll] = useState(false)
//     const [uacCount, setUacCount] = useState(0);


//     const onSelectCourse = (event) => {
//         setSelectedCourse(event?.target?.value);
//     };

//     const onSelectsubject = (event) => {
//         setSelectedSubject(event?.target?.value);
//     };

//     const handleChangeCourse = async () => {
//         const courseDetails = await getAllCourseDetails();

//         const coursesData = courseDetails?.data?.map((data) => ({
//             courseName: data?.courseDisplayName,
//             courseId: data?.courseId,
//         }));
//         setCourses(coursesData);

//     };

//     useEffect(() => {
//         handleChangeCourse();
//     }, []);


//     const fetchSubjectData = async (courseId) => {
//         try {
//             const subjectDetails = await getAllSubjectDetails(courseId);
//             const subjectsData = subjectDetails?.data?.map((data) => ({
//                 subjectName: data?.levelDisplayName,
//                 levelId: data?.levelId,
//             }));
//             setSubjects(subjectsData);
//         } catch (error) {
//             console.error('Error fetching subject details:', error);
//         }
//     };

//     useEffect(() => {
//         if (selectedCourse) {
//             const selectedCourseObject = courses.find((course) => course.courseName === selectedCourse);
//             if (selectedCourseObject) {
//                 fetchSubjectData(selectedCourseObject.courseId);
//             }
//         }
//     }, [selectedCourse, courses]);

//     const handleCreateUAC = (e) => {
//         setIsLoading(true)
//         e.preventDefault();
//         const selectedCourseObject = courses.find((course) => course.courseName === selectedCourse);
//         const selectedSubjectObject = subjects.find((subject) => subject.subjectName === selectedSubject);
//         const payload = {
//             course_id: selectedCourseObject.courseId,
//             max_registration_count: parseInt(uacCount, 10),
//             client_id: state,
//             is_all_subject: selectAll,
//             ...(selectAll ? {} : { subject_id: selectedSubjectObject.levelId }),
//         }

//         console.log(payload)
//         createUacForClient([payload]).then((respsone) => {
//             setIsLoading(false)
//             if (respsone.status === 200) {
//                 toast.success("UAC added!")
//                 console.log("success",respsone.data);
//             } else {
//                 console.log(respsone.data);
//                 toast.error("Error while adding UAC.")
//             }
//         }).catch((error) => {
//             setIsLoading(false)
//             toast.error('Error Occured!')
//         })
//     }

//     return (<>
//         {isLoading && <Loader />}
//         <Helmet>
//             <title> Dashboard: Create UAC for Client</title>
//         </Helmet>
//         <ToastContainer />

//         <Container>
//             <form onSubmit={handleCreateUAC}>
//                 <div className="main" style={{ display: 'flex' }}>
//                     <div className="col-md-3">
//                         <FormControl sx={{ m: 1, width: 200 }} size={'small'} required>
//                             <InputLabel id="demo-multiple-name-label">Courses</InputLabel>
//                             <Select labelId="demo-multiple-name-label" id="demo-multiple-name"
//                                 value={selectedCourse} onChange={(e) => onSelectCourse(e)}
//                                 input={<OutlinedInput label="Courses" />}
//                                 startAdornment={
//                                     <InputAdornment position="start">
//                                         {isLoading === true ? <CircularProgress size={15} /> : ''}
//                                     </InputAdornment>
//                                 }
//                             >
//                                 {courses.map((course) => (
//                                     <MenuItem key={course.courseId} value={course.courseName}>
//                                         {course.courseName}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                     </div>

//                     <div className="col-md-2" style={{ marginLeft: '10px' }}>
//                         <FormControl sx={{ m: 1, width: 200 }} size={'small'}>
//                             <InputLabel id="demo-multiple-name-label">Subject</InputLabel>
//                             <Select
//                                 labelId="demo-multiple-name-label"
//                                 id="demo-multiple-name"
//                                 value={selectedSubject}
//                                 onChange={onSelectsubject}
//                                 input={<OutlinedInput label="Subject" />}
//                                 startAdornment={
//                                     <InputAdornment position="start">
//                                         {isLoading === true ? <CircularProgress size={15} /> : ''}
//                                     </InputAdornment>
//                                 }
//                             >
//                                 {subjects.map((subject) => (
//                                     <MenuItem key={subject.levelId} value={subject.subjectName}>
//                                         {subject.subjectName}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                     </div>

//                     <FormControl sx={{ m: 1, width: 200 }} size={'small'}>
//                         <FormControlLabel control={<Checkbox value={selectAll} onClick={(e) => { setSelectAll(!selectAll); }} />} label="All Subject" />
//                     </FormControl>

//                     <TextField name="max_registration_count" label="UAC Count"
//                         type='number' value={uacCount} onChange={(e) => { setUacCount(e.target.value) }}
//                         style={{ width: '200px', marginBottom: '20px', marginLeft: '20px' }}
//                     />

//                     <TextField name="client_id" label="Client Id"
//                         type='number' value={state} disabled
//                         style={{ width: '200px', marginBottom: '20px', marginLeft: '20px' }}
//                     />
//                 </div>

//                 <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>

//                     <Button type="submit" variant="contained" sx={{ width: '120px', textAlign: 'center' }}>
//                         Create
//                     </Button>

//                     <Button type="button" variant="contained"
//                         sx={{ width: '120px', textAlign: 'center', marginLeft: '1rem', backgroundColor: 'white', color: 'black', }}
//                         onClick={() => { navigate(-1); }} >
//                         Cancel
//                     </Button>
//                 </div>
//             </form>
//         </Container>
//     </>);
// }
