import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

// import '../Styles/product.css';
import { FaEdit, FaSearch, FaTrashAlt } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../Styles/play.css';

import { ToastContainer, toast } from 'react-toastify';
import 'katex/dist/katex.min.css';

import { BlockMath } from 'react-katex';
import {
  getAllChapterDetails,
  getAllCourseDetails,
  getAllSubjectDetails,
  addAssessmentImport,
  getQuestionById,
  getQuestionBank,
} from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Iconify from '../components/iconify';
import Loader from '../components/common/Loader';
import EditQuestionDialog from '../components/common/EditQuestionDialog ';

export default function ManageTest() {
  const navigate = useNavigate();
  const [quizTags, setQuizTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [questionBank, setQuestionBank] = useState();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [Courses, setCourese] = useState([]);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedChapter, setSelectedChapter] = React.useState();
  const headers = ['SrNO', 'content', 'Status', 'Action'];
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState();
  const [dataStudent, setDataStudent] = useState([]);
  const [questionTableData, setQuestionTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [quizId, setQuizId] = useState('');
  const [addAssessData, setAddAssessData] = useState([]);

  console.log('check quizId', quizId);
  const [selectTags, setSelectTags] = useState('');
  const fileInputRef = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onSelectCourse = (event) => {
    setSelectedCourse(event?.target?.value);
  };

  const onSelectsubject = (event) => {
    setSelectedSubject(event?.target?.value);
  };

  const onSelectChapter = (event) => {
    setSelectedChapter(event?.target?.value);
  };

  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();

    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourese(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);
      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const fetchChapterData = async (courseId, subjectId) => {
    try {
      const chapterDetails = await getAllChapterDetails(courseId, subjectId);
      const chapterCode = chapterDetails?.data?.map((data) => ({
        chapterName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setChapter(chapterCode);
    } catch (error) {
      console.error('Error fetching chapter details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse && selectedSubject) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      if (selectedCourseObject && selectedSubjectObject) {
        fetchChapterData(selectedCourseObject.courseId, selectedSubjectObject.levelId);
      }
    }
  }, [selectedCourse, selectedSubject, Courses]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleFormSubmit = () => {
    const file = uploadedFile;
    if (selectedCourse && selectedSubject && selectedChapter) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === selectedChapter);

      if (selectedCourseObject && selectedSubjectObject && selectedChapterObject) {
        const courseId = selectedCourseObject.courseId;
        const subjectId = selectedSubjectObject.levelId;
        const chapterId = selectedChapterObject.levelId;

        setIsLoading(true);
        // Assuming you have a function like addQuizImport that accepts additional parameters
        addAssessmentImport(courseId, subjectId, chapterId, file)
          .then((response) => {
            if (response.data && response.data.success) {
              setAddAssessData(response.data);
              toast.success('File uploaded successfully!');
            } else {
              console.error('Unexpected response structure:', response.data);
            }

            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error('AddCourse error:', error);
          });
      }
    }
  };
  console.log('addAssessData', addAssessData);

  const handleNoOfQuesChange = (event) => {
    setNumberOfQuestions(event.target.value);
  };

  const handlesubmit = () => {
    if (selectedCourse && selectedSubject && selectedChapter) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === selectedChapter);

      if (selectedCourseObject && selectedSubjectObject && selectedChapterObject) {
        const courseId = selectedCourseObject.courseId;
        const subjectId = selectedSubjectObject.levelId;
        const chapterId = selectedChapterObject.levelId;

        console.log('Selected Course:', selectedCourse);
        console.log('Selected Subject:', selectedSubject);
        console.log('Selected Chapter:', selectedChapter);

        setIsLoading(true);
        setIsLoading(true);
        getQuestionById(courseId, subjectId, chapterId)
          .then((response) => {
            if (response.data !== null) {
              // setQuestionBank(response.data);
              setTableData(response.data.data);
              // console.log('check content', response.data);
              setIsCardVisible(true);
              setQuizId(response?.data?.quizId);
              setIsLoading(false);
            } else {
              setIsCardVisible(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error('Error:', error);
          });
      }
    }
  };
  useEffect(() => {
    console.log(tableData, 'tabledta');
    if (tableData?.questionsData?.length > 0) {
      const tableContent = tableData?.questionsData?.map((question, serialNumber) => {
        // Check if title array has multiple objects
        if (question.title.length > 1) {
          // Concatenate titles into a single line
          const concatenatedTitles = question.title.reduce((acc, curr) => {
            if (curr.type === 'latex') {
              acc += `<BlockMath math="${curr.content}" />`; // For latex type
            } else if (curr.type === 'image') {
              acc += `<img src="${curr.content}" alt="Image" />`; // For img type
            } else {
              acc += `<span style="font-family: ${curr.font}; font-size: 20px;">${curr.content}</span>`; // For text type
            }
            return acc;
          }, '');

          return {
            SrNO: serialNumber + 1,
            content: <span dangerouslySetInnerHTML={{ __html: concatenatedTitles }} />, // Render as HTML
            Status: question.isActive ? (
              <Button variant="contained" color="primary">
                Active
              </Button>
            ) : (
              ''
            ),
            Action: (
              <div>
                <FaEdit
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => { openEditQuestionDialog(question) }}
                // onClick={() => handleEditUser(question.id)} // Pass the user ID to the edit handler
                />
              </div>
            ),
          };
        }
        // Handle single title object case
        const title = question.title[0];
        return {
          SrNO: serialNumber + 1,
          content:
            title?.type === 'latex' ? (
              <BlockMath math={title.content} />
            ) : (
              <span style={{ fontFamily: title?.font, fontSize: '20px' }}>{title?.content}</span>
            ),
          Status: question.isActive ? (
            <Button variant="contained" color="primary">
              Active
            </Button>
          ) : (
            ''
          ),
          Action: (
            <div>
              <FaEdit
                style={{ cursor: 'pointer', marginRight: '10px' }}
                onClick={() => { openEditQuestionDialog(question) }}
              // onClick={() => handleEditUser(question.id)} // Pass the user ID to the edit handler
              />
            </div>
          ),
        };
      });

      // Concatenate the arrays generated from mapping through questions with multiple titles
      const concatenatedTableContent = [].concat(...tableContent);
      setQuestionTableData(concatenatedTableContent);
    }
  }, [tableData]);

  const openEditQuestionDialog = (question) => {
    setCurrentQuestion(question);
    setOpenDialog(true);
  };

  const closeEditQuestionDialog = () => {
    setOpenDialog(false);
    setCurrentQuestion(null);
  };

  return (
    <>
      {isLoading && <Loader />}

      <ToastContainer />

      <Helmet>
        <title> Dashboard: Notifications </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Get Question Bank
          </Typography>
          <CardContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {uploadedFile ? (
                <>
                  <Typography variant="body1" style={{ marginLeft: '10px' }}>
                    {uploadedFile.name}
                  </Typography>
                  <IconButton color="secondary" onClick={() => setUploadedFile(null)}>
                    <FaTrashAlt />
                  </IconButton>
                  <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                    Upload
                  </Button>
                </>
              ) : (
                <>
                  <label htmlFor="containedfile">
                    <Button variant="contained" color="primary" component="span" id="contained-button-file">
                      Import
                    </Button>
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx,.xls,.xlsx"
                      style={{ display: 'none' }}
                      id="containedfile"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                    />
                  </label>
                </>
              )}
            </div>
          </CardContent>
        </Stack>

        <form>
          <div className="main" style={{ display: 'flex' }}>
            <div className="col-md-3">
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Courses</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedCourse}
                  onChange={(e) => onSelectCourse(e)}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {Courses.map((course) => (
                    <MenuItem key={course.courseId} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
            <div className="col-md-3" style={{ marginLeft: '10px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Subject</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedSubject}
                  onChange={onSelectsubject}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {subject.map((subject) => (
                    <MenuItem key={subject.levelId} value={subject.subjectName}>
                      {subject.subjectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-3" style={{ marginLeft: '10px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Chapater</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedChapter}
                  onChange={onSelectChapter}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {chapter.map((chapter) => (
                    <MenuItem key={chapter.levelId} value={chapter.chapterName}>
                      {chapter.chapterName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handlesubmit}
              sx={{ width: '120px', height: '40px', marginTop: '10px', textAlign: 'center' }}
            >
              Search
            </Button>
          </div>
        </form>
        <br />
        {isCardVisible && (
          <Card>
            <div style={{ alignItems: 'center' }}>
              <TableData
                // data={sortedData.length === 0 ? questionBankData : sortedData}
                data={questionTableData}
                headers={headers}
                rowsPerPageOptions={[5, 10, 20]}
              />
              <EditQuestionDialog
                open={openDialog}
                question={currentQuestion}
                onClose={closeEditQuestionDialog}
              />
            </div>
          </Card>
        )}
      </Container>
    </>
  );
}
