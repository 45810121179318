import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import {
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  IconButton,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit, FaTrash, FaTrashAlt, FaDownload } from 'react-icons/fa';
import { getInvoiceList, getInvoiceExcel, importAwbReport } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function InvoiceList() {
  const [search, setSearch] = useState('');
  const headers = [
    'Select',
    'Id',
    'Order Id',
    'Transaction Date',
    'Customer Name',
    'Product',
    'Amount',
    'Invoice No',
    'Order Status',
    'Action',
  ];
  const [showTable, setShowTable] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPageSize, setTotalPageSize] = useState(1)
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('All');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [addAwbData, setAddAwbData] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    setOrderStatus(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleExportClick = () => {
    console.log('Export clicked');
  };

  const handleNameChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchInvoices();
    }
  };

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await getInvoiceList({
        page: pageNo - 1,
        length: pageSize,
        search,
        order: [[0, 'desc']],
        data: {
          orderStatus: orderStatus === 'All' ? '' : orderStatus,
          fromDate: startDate,
          toDate: endDate,
        },
      });
      setShowTable(response.data.data);
      setTotalRecords(response.data.recordsTotal);
      setTotalPageSize(response.data.recordsTotal);
    } catch (error) {
      toast.error('Failed to fetch invoice data');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClick = () => {
    setPageNo(1); // Reset to first page on new search
    fetchInvoices();
  };

  useEffect(() => {
    fetchInvoices();
  }, [pageNo, pageSize]);

  const handleCheckboxChange = (invoiceNumber) => {
    setSelectedInvoices((prevSelected) =>
      prevSelected.includes(invoiceNumber)
        ? prevSelected.filter((num) => num !== invoiceNumber)
        : [...prevSelected, invoiceNumber]
    );
  };


  const downloadInvoices = async (invoiceNumber) => {
    const invoicesToDownload = selectedInvoices.length > 0 ? selectedInvoices : [invoiceNumber];
    console.log(selectedInvoices)
    if (invoicesToDownload.length > 0) {
      try {
        const response = await getInvoiceExcel(invoicesToDownload);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // Generate current date string
        const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
        // Create the file name with the current date
        const fileName = `ShipRocket_${currentDate}.zip`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Error downloading the file');
        toast.error('Error downloading the file');
      }
    } else {
      toast.warn('No invoices selected');
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleFormSubmit = () => {
    const file = uploadedFile;
    setIsLoading(true);
    // Assuming you have a function like addQuizImport that accepts additional parameters
    importAwbReport(file)
      .then((response) => {
        if (response.data && response.data.success) {
          setAddAwbData(response.data);
          toast.success('File uploaded successfully!');
        } else {
          console.error('Unexpected response structure:', response.data);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('AddCourse error:', error);
      });
  };
  console.log('addAssessData', addAwbData);

  const specificColumns = Array.isArray(showTable)
    ? showTable.map((item, index) => {
      return {
        Select: (
          <Checkbox
            id={`select-checkbox-${index}`}
            checked={selectedInvoices.includes(item.invoiceNumber)}
            onChange={() => handleCheckboxChange(item.invoiceNumber)}
          />
        ),
        Id: item.id,
        'Order Id': item.orderId,
        'Transaction Date': formatDate(item.transactionDate),
        'Customer Name': item.customerName,
        Product: item.productTitle,
        Amount: item.totalsalevalue,
        'Invoice No': item.invoiceNumber,
        'Order Status': item.finalStatus,
        Action: (
          <div>
            <FaDownload style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => { downloadInvoices(item.invoiceNumber); }} />
          </div>
        ),
      };
    })
    : [];

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Client </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Invoice List - Shipping
          </Typography>
        </Stack>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <FormControl style={{ width: '200px' }}>
            <InputLabel id="demo-simple-select-autowidth-label">Order Status</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={orderStatus}
              onChange={handleChange}
              autoWidth
              label="Order Status"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="InProcess">InProcess</MenuItem>
              <MenuItem value="Ready To Dispatch">Ready To Dispatch</MenuItem>
              <MenuItem value="Dispatched">Dispatched</MenuItem>
              <MenuItem value="AWB Received">AWB Received</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
              <MenuItem value="Order Cancelled">Order Cancelled</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="start-date"
            label="Start Date"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant="contained" color="primary" onClick={handleSearchClick}>
            Search
          </Button>
        </div>
        <br />
        <div style={{ display: 'flex', gap: '2rem' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {uploadedFile ? (
              <>
                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                  {uploadedFile.name}
                </Typography>
                <IconButton color="secondary" onClick={() => setUploadedFile(null)}>
                  <FaTrashAlt />
                </IconButton>
                <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                  Update AWB
                </Button>
              </>
            ) : (
              <>
                <label htmlFor="containedfile">
                  <Button variant="contained" color="primary" component="span" id="contained-button-file">
                    Import
                  </Button>
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    style={{ display: 'none' }}
                    id="containedfile"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                  />
                </label>
              </>
            )}
          </div>
          <Button variant="contained" color="primary" onClick={downloadInvoices}>
            Submit Selected
          </Button>
          <TextField
            id="search-name"
            label="Search by Name"
            type="text"
            value={search}
            onChange={handleNameChange}
            onKeyDown={handleSearchKeyPress}
            style={{ marginLeft: '500px' }}
          />
          <FormControl style={{ width: '150px' }}>
            <InputLabel id="page-size-select-label">Page Size</InputLabel>
            <Select
              labelId="page-size-select-label"
              id="page-size-select"
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
              autoWidth
              label="Page Size"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={totalPageSize}>All</MenuItem>
            </Select>
          </FormControl>
        </div>
        <br />
        <TableData
          data={specificColumns}
          headers={headers}
          rowsPerPageOptions={[5, 10, 20]}
          page={pageNo}
          pageSize={pageSize}
          totalRecords={totalRecords}
          onPageChange={(newPage) => setPageNo(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
        <br />
      </Container>
    </>
  );
}
