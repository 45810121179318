import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  CircularProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AssignCoursePopup } from '../sections/@dashboard/user';
import Loader from '../components/common/Loader';

import { addUsers, getUser } from '../Servies/AdmiServices';

export default function ManageUser() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = new URLSearchParams(location.search).get('userId');
  const [isCountryCodeSelected, setIsCountryCodeSelected] = useState(false);
  const handleOpenPopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleClosePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handlepage = () => {
    navigate(-1);
  };

  const [formData, setFormData] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/\D/g, '').slice(0, 10);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      // [name]: numericValue,
    }));
    if (name === 'mobile') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        username: value,
        [name]: numericValue, // Set userName to the mobile number input value
      }));
    }

    if (name === 'countryCode') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      deviceId: 'test',
    }));
    setIsCountryCodeSelected(value !== '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    addUsers(formData)
      .then((response) => {
        if (response?.data.success === 'true') {
          setIsLoading(false);

          toast.success(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // toast.error(response?.data?.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      });
  };

  const [checked, setChecked] = useState(false);
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (userId) {
      getUser(userId)
        .then((response) => {
          setUserData(response.data?.Data);
          setChecked(response.data?.Data?.isActive);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Dashboard: ManageUser </title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Manage User
        </Typography>

        <Card sx={{ padding: 3, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label="Full Name"
                    fullWidth
                    value={formData?.name || userData?.name || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                {userData?.userName === '' || userData?.userName === undefined ? (
                   <>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="country-code-label">Country Code</InputLabel>
                    <Select
                      name="countryCode"
                      labelId="country-code-label"
                      id="country-code-select"
                      value={formData?.countryCode || ''}
                      onChange={handleInputChange}
                      label="Country Code"
                    >
                      <MenuItem value={''}>Select</MenuItem>
                      <MenuItem value={'+91'}>+91</MenuItem>
                      <MenuItem value={'+974'}>+974</MenuItem>
                      <MenuItem value={'+971'}>+971</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
               
                 
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="mobile"
                        label="Phone No"
                        fullWidth
                        value={formData?.mobile || userData?.userName || ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="mobile"
                      label="Phone No"
                      fullWidth
                      value={formData?.mobile || userData?.userName || ''}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    label="Email Id"
                    fullWidth
                    value={formData?.email !== null ? formData?.email : userData?.email || ''}
                    // onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="password"
                    label="Password"
                    fullWidth
                    value={formData?.password || userData?.password || ''}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="address"
                    label="Address"
                    fullWidth
                    value={formData?.address || userData?.address || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="city"
                    label="Location"
                    value={formData?.city || userData?.location || ''}
                    fullWidth
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="pincode"
                    label="Pincode"
                    fullWidth
                    value={formData?.pincode || userData?.pincode || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="school"
                    label="school"
                    fullWidth
                    value={formData?.school || userData?.school || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="state"
                    label="state"
                    fullWidth
                    value={formData?.state || userData?.state || ''}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label="isActive" />
                </Grid>
                {userId && (
                  <Grid item xs={12} sm={3}>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ textAlign: 'center', backgroundColor: 'Gray' }}
                      onClick={handleOpenPopup}
                    >
                      Assign Course
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ width: '120px', textAlign: 'center' }}
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{
                        width: '120px',
                        textAlign: 'center',
                        marginLeft: '1rem',
                        backgroundColor: 'white',
                        color: 'black',
                      }}
                      onClick={handlepage}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </CardContent>

          <AssignCoursePopup open={isPopupOpen} onClose={handleClosePopup} userId={userId} />
        </Card>
      </Container>
    </>
  );
}
