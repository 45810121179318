import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  SerachUser,
  assignRole,
  getAllCourseDetails,
  getAllSubjectDetails,
  getnotification,
} from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function AssignRole() {
  const [userdata, setUserData] = useState([]);
  const [roledata, setRoleData] = useState({});
  const [phoneNumber, setphoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [Name, setName] = useState('');
  const [roles, setRoles] = useState();
  const [Number, setNumber] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showRoleDropdown, setShowRoleDropdown] = useState(false);
  const headers = ['Name', 'UserName', 'Roles'];
  const handleMobileNumberChange = (e) => {
    setphoneNumber(e.target.value);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!phoneNumber) {
      // Both name and mobil
      console.log('Please enter mobile number and select a role.');
      setIsLoading(false);
      return;
    }

    SerachUser(Name, phoneNumber)
      .then((res) => {
        if (res?.data?.SUCCESS === true) {
          setUserData(res?.data?.Data);
          setNumber(res?.data?.Data[0]?.userName);
          setRoles(res?.data?.Data[0]?.roles);
          setShowRoleDropdown(true);
        } else {
          setShowRoleDropdown(false);
          // handle no matching users found case
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // handle error
        setIsLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!Number && !role) {
      // Both name and mobil
      console.log('Please enter mobile number and select a role.');
      setIsLoading(false);
      return;
    }

    assignRole(Number, role)
      .then((res) => {
        // if (res?.SUCCESS === true) {
        setRoleData(res?.data?.data);
        setShowRoleDropdown(false);
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setIsLoading(false);

        // } else {
        //   // toast.error(res?.data?.message, {
        //   //   position: toast.POSITION.TOP_RIGHT,
        //   // });
        //   setIsLoading(false);
        // }
      })
      .catch((error) => {
        // handle error
        setIsLoading(false);
      });
  };

  const users = userdata
    .filter((item) => item !== null)
    .map((item) => {
      return {
        Name: item?.name,
        userName: item?.userName,
        Roles: roles.map((role) => role.name).join(', '),
      };
    });
  const responseArray = Object.values(roledata).map((item) => {
    if (Array.isArray(item)) {
      return {
        Name: item?.name,
        userName: item?.userName,
        Roles: roles.map((role) => role.name).join(', '),
      };
    }
    return item;
  });

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Dashboard: Assign Role </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Assign Role
          </Typography>
        </Stack>

        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                name="phoneNumber"
                label="Mobile No"
                fullWidth
                onChange={handleMobileNumberChange}
                value={phoneNumber}
              />
            </Grid>
            {showRoleDropdown && (
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="role-label">Select Role</InputLabel>
                  <Select
                    labelId="role-label"
                    id="role-select"
                    label="Select Role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={'STUDENT'}>Student</MenuItem>
                    <MenuItem value={'PUBLISHER'}>Publisher</MenuItem>
                    <MenuItem value={'ADMIN'}>Admin</MenuItem>
                    <MenuItem value={'QUESTION_AUTHOR'}>Question Author</MenuItem>
                    <MenuItem value={'MARKETING'}>Marketing</MenuItem>
                    <MenuItem value={'REPORT_USER'}>Report User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                {showRoleDropdown ? (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: '120px', textAlign: 'center' }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: '120px', textAlign: 'center' }}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
        {showRoleDropdown ? (
          <Card>
            {users.length > 0 ? (
              <TableData data={users} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
            ) : (
              'No Data Found'
            )}
          </Card>
        ) : (
          <TableData data={responseArray} headers={headers} rowsPerPageOptions={[5, 10, 20]} />
        )}
      </Container>
    </>
  );
}
