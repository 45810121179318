import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

// import '../Styles/product.css';
import { FaEdit, FaSearch, FaTrashAlt } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../Styles/play.css';

import { ToastContainer, toast } from 'react-toastify';
import 'katex/dist/katex.min.css';

import { BlockMath } from 'react-katex';
import {
  getAllChapterDetails,
  getAllCourseDetails,
  getAllSubjectDetails,
  generateContentQR,
} from '../Servies/AdmiServices';

import Loader from '../components/common/Loader';

export default function GenerateQRContent() {
  const [isLoading, setIsLoading] = useState(false);
  const [Courses, setCourese] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onSelectCourse = (event) => {
    setSelectedCourse(event?.target?.value);
  };

  const onSelectsubject = (event) => {
    setSelectedSubject(event?.target?.value);
  };

  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();

    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourese(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);
      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const fetchChapterData = async (courseId, subjectId) => {
    try {
      const chapterDetails = await getAllChapterDetails(courseId, subjectId);
      const chapterCode = chapterDetails?.data?.map((data) => ({
        chapterName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));
    } catch (error) {
      console.error('Error fetching chapter details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse && selectedSubject) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      if (selectedCourseObject && selectedSubjectObject) {
        fetchChapterData(selectedCourseObject.courseId, selectedSubjectObject.levelId);
      }
    }
  }, [selectedCourse, selectedSubject, Courses]);

  const handlesubmit = () => {
    if (selectedCourse && selectedSubject) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);

      if (selectedCourseObject && selectedSubjectObject) {
        const courseId = selectedCourseObject.courseId;
        const subjectId = selectedSubjectObject.levelId.toString();

        console.log('Selected Course:', selectedCourse);
        console.log('Selected Subject:', selectedSubject);
        console.log('Course ID:', courseId);
        console.log('Subject ID:', subjectId);

        setIsLoading(true);

        generateContentQR(courseId, subjectId)
          .then(async (response) => {
            console.log('API Response:', response);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            try {
              const blob = await response.blob();
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              // Generate current date string
              const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
              // Create the file name with the current date
              const fileName = `QR_Codes_${currentDate}.zip`;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
            } catch (error) {
              console.log('Error downloading file:', error);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error:', error);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <ToastContainer />

      <Helmet>
        <title> Dashboard: Notifications </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Generate Content QR{' '}
          </Typography>
          <CardContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          />
        </Stack>

        <form>
          <div className="main" style={{ display: 'flex' }}>
            <div className="col-md-3">
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Courses</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedCourse}
                  onChange={(e) => onSelectCourse(e)}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {Courses.map((course) => (
                    <MenuItem key={course.courseId} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
            <div className="col-md-3" style={{ marginLeft: '10px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Subject</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedSubject}
                  onChange={onSelectsubject}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {subject.map((subject) => (
                    <MenuItem key={subject.levelId} value={subject.subjectName}>
                      {subject.subjectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handlesubmit}
              sx={{ width: '120px', height: '40px', marginTop: '10px', textAlign: 'center' }}
            >
              Generate
            </Button>
          </div>
        </form>
      </Container>
    </>
  );
}
