import React, { useState, useEffect } from 'react';
import { BlockMath,InlineMath } from 'react-katex';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const EditQuestionDialog = ({ open, question, onClose }) => {
    const [editableQuestion, setEditableQuestion] = useState(null);

    useEffect(() => {
        setEditableQuestion(question);
        console.log(question)
    }, [question]);

    const handleContentChange = (section, index, itemIndex, value) => {
        const updatedQuestion = { ...editableQuestion };
        if (section === 'title') {
            updatedQuestion.title[itemIndex].content = value;
        } else if (section === 'answer') {
            updatedQuestion.answers[index].title[itemIndex].content = value;
        }
        setEditableQuestion(updatedQuestion);
    };

    const handleSave = () => {
        console.log('Updated Question:', editableQuestion);
        onClose();
    };

    const renderContent = (content, section, index) => {
        return content.map((item, itemIndex) => {
            switch (item.type) {
                case 'latex_image':
                    return (
                        <div key={itemIndex}>
                            <TextField
                                label={section === 'answer' ? `Answer ${index + 1}` : 'Question'}
                                value={item.content}
                                onChange={(e) => handleContentChange(section, index, itemIndex, e.target.value)}
                                fullWidth multiline
                                style={{fontFamily: item.font, fontSize: '16px', margin: '5px' }}
                                rows={1}
                            />
                            <div>
                            <span>Latex Preview</span>
                            <BlockMath math={item.content} />
                            </div>
                        </div>
                    );
                    case 'latex':
                        return (
                            <div key={itemIndex}>
                                <TextField
                                    label={section === 'answer' ? `Answer ${index + 1}` : 'Question'}
                                    value={item.content}
                                    onChange={(e) => handleContentChange(section, index, itemIndex, e.target.value)}
                                    fullWidth multiline
                                    style={{fontFamily: item.font, fontSize: '16px', margin: '5px' }}
                                    rows={1}
                                />
                                <div style={{margin: '5px'}}>
                                <span>Latex Preview : </span>
                                <InlineMath math={item.content.replace(/〖/g, '\\angle ').replace(/〗/g, '').replace(/\^0\\/g, '^\\circ').replace(/\\/g, '')} />
                                </div>
                            </div>
                        );
                case 'image':
                    return (
                        <div key={itemIndex}>
                            <img src={item.content} alt="mage" style={{ maxWidth: '100%',padding:'5px' }} />
                            <TextField
                                label={section === 'answer' ? `Answer ${index + 1}` : 'Question'}
                                value={item.content}
                                onChange={(e) => handleContentChange(section, index, itemIndex, e.target.value)}
                                style={{fontFamily: item.font, fontSize: '16px', margin: '5px' }}
                                fullWidth
                            />
                        </div>
                    );
                case 'text':
                default:
                    if (item.content !== "") {
                        return (
                            <TextField
                                key={itemIndex}
                                label={section === 'answer' ? `Answer ${index + 1}` : 'Question'}
                                value={item.content}
                                onChange={(e) => handleContentChange(section, index, itemIndex, e.target.value)}
                                fullWidth multiline rows={1}
                                style={{ fontFamily: item.font, fontSize: '16px', margin: '5px' }}
                            />
                        );
                    }
                    return ''
            }
        });
    };

    const renderQuestionOrAnswer = (items, section, index) => {
        return renderContent(items, section, index);
    };

    return (
        <Dialog
            fullWidth={'xl'}
            open={open}
            onClose={onClose}
            scroll={'body'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Edit Question</DialogTitle>
            <DialogContent >
                {editableQuestion ? (
                    <div>
                        <div>
                            <strong>Question:</strong>
                            {renderQuestionOrAnswer(editableQuestion.title, 'title', 0)}
                        </div>
                        <div id="scroll-dialog-description">
                            <strong>Answer:</strong>
                            {editableQuestion.answers.map((answer, index) => (
                                <div key={index}>
                                    {renderQuestionOrAnswer(answer.title, 'answer', index)}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditQuestionDialog;
